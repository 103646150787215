<template>
  <div class="bigscreenheader">
    <el-col :span="8">
      <dv-decoration-8 style="height:80px;"></dv-decoration-8>
    </el-col>
    <el-col :span="8">
      <dv-decoration-5 style="width:100%;height:80px;">123</dv-decoration-5>
    </el-col>
    <el-col :span="8">
      <dv-decoration-8 :reverse="true" style="width:100%;height:80px;"></dv-decoration-8>
    </el-col>
    <div class="header-main-text">无线温度监测系统</div>
    <dv-border-box-2 class="header-right-top" v-show="false">
      <a href="#" style="color:white" @click="reloadPage">刷新</a>
    </dv-border-box-2>
  </div>
</template>

<script>
export default {
  methods: {
    reloadPage() {
      location.reload();
    },
  },
  mounted() {},
};
</script>

<style scoped>
.bigscreenheader {
  height: 80px;
}
.header-main-text {
  position: absolute;
  text-align: center;
  width: 100%;
  height: 70px;
  color: white;
  font-size: 30px;
}
.header-right-top {
  position: absolute;
  text-align: center;
  right: 50px;
  top: 5px;
  line-height: 50px;
  width: 100px;
  height: 50px;
  color: white;
  font-size: 20px;
}
</style>