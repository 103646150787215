export const lang = {
  语言: "中文",
  登录: "Login",
  微信登录: "WeChat",
  账户登录: "Account",
  请使用微信扫描二维码登录: "Please use WeChat to scan the QR code to log in",
  公司版权所有北京路晨伟业仪器设备有限公司: "Company copyright: Beijing Sumj Instrument Equipment Co., Ltd",
  京ICP备18062262号1: " ICP:18062262-1",
  设备数据: "Device data",
  首页: "Home",
  手动单通道: "Manual single channel",
  自动单通道: "Automatic single channel",
  手动双通道: "Manual dual channel",
  自动双通道: "Automatic dual channel",
  单通道带剪切力: "Manual single channel with shear force",
  静力触探: "Static penetration",
  修改密码: "Change the password",
  退出: "quit",
  旧密码: "Old password",
  新密码: "New password",
  确认新密码: "Confirm the new password",
  保存: "Save",
  取消: "Canel",
  密码至少为6位: "The password must be at least 6 digits",
  密码错误: "The password is incorrect",
  微信用户: "WeChat users",
  确定要退出吗: "Are you sure you want to quit?",
  新增绑定设备: "Added bound devices",
  设备序列号: "Serial Number",
  经销商电话: "Dealer Phone",
  备注: "Remark",
  绑定: "Bind",
  重置: "Reset",
  序列号: "Serial Number",
  已绑定设备: "The device is bound",
  设备类型: "Device type",
  绑定时间: "Bind time",
  操作: "Operate",
  解绑: "Unbind",
  请输入序列号: "Please enter the serial number",
  请输入经销商电话: "Please enter your dealer number",
  请输入备注: "Please enter a comment",
  确定要解绑吗: "Are you sure you want to untie?",
  由斯姆捷科技有限公司提供技术支持: "Technical support prompted by Sumj Technology Co., Ltd",
  日期: "Date",
  孔号: "Num.",
  试验时间: "Test time",
  截面积: "C.Area",
  表面积: "S.Area",
  位移点长度: "Length",
  起始深度: "Depth",
  打印: "Print",
  导出: "Exp",
  删除: "Del",
  图形: "graphics",
  详细信息: "detail",
  深度: "depth",
  阻力: "resistance",
  锥尖阻力: "Cone tip resistance",
  确定要删除吗: "Are you sure you want to delete it?",
  深度压强: "Depth/pressure",
  压强: "pressure",
  全屏: "View in full screen",
  打印图表: "Print chart",
  导出JPEG: "Download JPEG image",
  导出PDF: "Download PDF document",
  导出PNG: "Download PNG image",
  导出SVG: "Download SVG vector image",
  试验编号: "Num.",
  侧壁阻力: "Sidewall resistance",
  深度锥尖阻力: "Depth/cone tip resistance",
  深度侧壁阻力: "Depth/sidewall resistance",
  土类型: "Soil type",
  工程编号: "Project number",
  试验深度: "Depth",
  运行系数: "Operating coefficient",
  孔口高度: "Height",
  地下水位: "level",
  角度: "angle",
  抗剪强度: "Shear strength",
  存在空数据: "There is empty data",
  请选择一条原状土和一条重塑土记录: "Please select a piece of unaltered soil and a record of reshaped soil",
  角度抗剪强度: "Angle/shear strength",
  修改成功: "Save successfully",
  密码不一致: "The passwords are inconsistent",
  修改失败: "Modification failed",
  请求失败: "The request failed!",
  请输入账号: "Please enter the account number",
  请输入密码: "Please enter a password",
  重塑土: "Remolded soil",
  原状土: "Undisturbed soil",
  测缝仪: "Sum tester",
  测缝仪数据查询: "Sum tester data query",
  构件: "component",
  最大缝宽: "Max width",
  最小缝宽: "Min width",
  平均缝宽: "Avg width",
  加载中: "loading",
  测点: "point",
  缝宽: "width",
  工程管理: "project management",
  增加: "Add",
  编辑: "Edit",
  测位管理: "Position management",
  测点管理: "Measurement point management",
  无数据: "No Data",
  工程信息: "Engineering information",
  工程名称: "Project name",
  开始时间: "Start time",
  结束时间: "End time",
  测位信息: "Position measurement information",
  测位名称: "Side name",
  测点信息: "Measurement point information",
  测点名称: "Side point name",
  请输入测点名称: "Please enter a name for the measurement point",
  请选择设备: "Please select a device",
  设备通道: "Device channel",
  请选择通道: "Please select a channel",
  请输入工程名称: "Please enter a project name",
  请输入开始日期: "Please enter a start date",
  请输入结束日期: "Please enter an end date",
  请输入测位名称: "Please enter a side name",
  测点设备: "Measuring point equipment",
  测点通道: "Measurement point channel",
  表层: "surface",
  中: "middle",
  中上: "Upper",
  中下: "Lower",
  底层: "underlying",
  环境: "environment",
  请选择项目: "Please select a project",
  删除成功: "The deletion was successful",
  保存成功: "Save successfully",
  请选择测位: "Please select a position",
  开始日期: "Start date",
  结束日期: "End date",
  公司名称: "Company name",
  修改公司名称: "Modify the company name",
  请选择测点: "Please select a measurement point",
  选择日期: "Select a date",
  我的工程: "My project",
  测位列表: "List of positions",
  请选择工程: "Please select a project",
  请至工程管理增加测位: "Please go to Project Management to add sideways",
  导出峰值数据: "Export data",
  原始数据导出见我的设备: "The raw data is exported to My Device",
  该测位下无测点: "There are no measurement points under this lateral position",
  通道: "Channel",
  最新温度: "Latest temperature",
  返回时间: "Acquisition time",
  峰值: "Peak",
  峰值时间: "Peak time",
  谷值: "Valley",
  谷值时间: "Trough time",
  平均值: "average value",
  测点无数据: "There is no data at the measurement point",
  项目起止: "Project start and end",
  请选择日期: "Please select a date",
  温度: "temperature",
  时间: "Time",
  温度曲线图: "Temperature graph",
  温差报警: "Temperature difference alarm",
  上下限报警: "Upper and lower limit alarms",
  每天降温报警: "Daily cooling alarm",
  每四小时降温报警: "Cooling alarm every four hours",
  温差名称: "Temperature difference name",
  设置差值: "Set the difference",
  通道1: "Channel 1",
  通道1时间: "Channel 1 time",
  通道1温度: "Channel 1 temperature",
  通道2: "Channel 2",
  通道2时间: "Channel 2 time",
  通道2温度: "Channel 2 temperature",
  上下限名称: "Upper and lower limit names",
  通道时间: "Channel time",
  通道温度: "Channel temperature",
  上限值: "Upper limit value",
  下限值: "Lower limit value",
  时间间隔: "Time interval",
  平均温度: "Average temperature",
  降温速率报警与最大温度: "Cooling rate alarm with maximum temperature",
  选择设备: "Select the device",
  每天降温: "Cool down daily",
  每四小时降温: "Cool down every four hours",
  T1最大温度: "T1 maximum temperature",
  T2最大温度: "T2 maximum temperature",
  T3最大温度: "T3 maximum temperature",
  T4最大温度: "T4 maximum temperature",
  T5最大温度: "T5 maximum temperature",
  T6最大温度: "T6 maximum temperature",
  T7最大温度: "T7 maximum temperature",
  T8最大温度: "T8 maximum temperature",
  水泵绑定: "Water pump binding",
  报警间隔: "Alarm interval",
  差值: "Difference",
  分钟: "Minute",
  名称: "Name",
  开关: "Switch",
  请输入开关imei: "Please enter the switch IMEI",
  继电器1: "Relay 1",
  继电器2: "Relay 2",
  继电器3: "Relay 3",
  继电器4: "Relay 4",
  开关明细: "Switch details",
  关闭: "Close",
  请输入温差名称: "Please enter the temperature difference name",
  请选择工程名称: "Please select a project name",
  请输入报警间隔: "Please enter the alarm interval",
  报警间隔必须为数字值: "The alarm interval must be a numeric value",
  请输入差值: "Please enter the difference",
  请输入上下限名称: "Please enter upper and lower limit names",
  创建时间: "Creation time",
  请输入名称: "Please enter a name",
  长度15个字符: "Length: 15 characters",
  请选择继电器: "Please select a relay",
  开关imei: "Switch IMEI",
  继电器: "Relays",
  明细: "Details",
  请选择一条记录: "Please select a record",
  电池电量: "Battery level",
  无记录: "No records",
  刷新: "Refresh",
  天: "Day",
  未配置工程信息: "No project information is configured",
  工程: "Project",
  测位: "Position",
  数字格式不正确: "The number format is incorrect",
  时间格式不正确: "The time format is incorrect",
  至: "to",
  小时: "Hour",
  无线测温仪: "Thermometer",
  报警管理: "Alarm management",
  我的设备: "My Device",
  平均温度查询: "Average temperature",
  报警记录查询: "Query alarm records",
  公司名称不能为空: "The company name cannot be empty",
  二维码: "QR",
  工程地址: "Address",
  请输入工程地址: "Please enter the address of the project",
  测点绑定: "point binding",
  传感器序列号: "Sensor number",
  不能为空: "It can't be empty",
  确定要覆盖吗: "Are you sure you want to cover?",
  已绑定: "Bind",
  请选择: "Please select",
  多次: "many",
  是: "Yes",
  否: "No",
  其他人绑定中: "Others are being bound",
  降温速率报警: "Cooling rate alarm",
  前一天: "Before",
  后一天: "After",
  报警记录: "Alarm logging",
  编辑名称: "Edit the name",
  查看数据: "Review the data",
  解除绑定: "Unbind",
  原始数据: "raw Data",
  计算结果: "Calculation results",
  标定点: "Calibration points",
  电势: "potential",
  计算: "calc",
  空: "empty",
  请输入温度: "Please enter a temperature",
  电阻率: "Resistivity",
  电阻率数据查询: "Resistivity data query",
  //侯棒棒
  传感器: "sensor",
  传感器1: "Sensor 1",
  传感器2: "Sensor 2",
  传感器温度: "Sensor temperature",
  传感器1温度: "Sensor 1 temperature",
  传感器2温度: "Sensor 2 temperature",
  传感器时间: "Sensor time",
  传感器1时间: "Sensor 1 time",
  传感器2时间: "Sensor 2 time",
  全部标为已读: "Mark all as read",
  上限值必须为数字值: "The upper limit value must be a numerical value",
  下限值必须为数字值: "The lower limit value must be a numerical value",
  请输入上限值: "Please enter the upper limit value",
  请输入下限值: "Please enter the lower limit value",
  背光时长: "Backlight duration",
  采样间隔: "Sampling interval",
  是否开机升级: "Whether to start up and upgrade",
  请输入背光时长: "Please enter the backlight duration",
  请输入采样间隔: "Please enter the sampling interval",
  保存失败: "Save failure",
  必须为数字值: "Must be a numerical value",
  密码为四位数字: "The password is four digits",
  必须为整数: "Must be an integer",
  对接公司名称: "Docking company name",
  t1序列号: "t1_SensorNumber",
  t2序列号: "t2_SensorNumber",
  t3序列号: "t3_SensorNumber",
  t4序列号: "t4_SensorNumber",
  t5序列号: "t5_SensorNumber",
  t6序列号: "t6_SensorNumber",
  t7序列号: "t7_SensorNumber",
  t8序列号: "t8_SensorNumber",
};

export const vxe = {
  base: {
    pleaseInput: "Please input",
    pleaseSelect: "Select",
  },
  loading: {
    text: "Loading...",
  },
  error: {
    groupFixed: "If you use group headers, the fixed columns must be set by group.",
    groupMouseRange: 'Grouping headers and "{0}" cannot be used at the same time, which may cause errors.',
    groupTag: 'Grouping column header should use "{0}" instead of "{1}", which may cause errors.',
    scrollErrProp: 'The parameter "{0}" is not supported when virtual scrolling is enabled.',
    errConflicts: 'Argument "{0}" conflicts with "{1}"',
    unableInsert: "Unable to insert to the specified location.",
    useErr: 'Error installing "{0}" module, possibly in the wrong order, dependent modules need to be installed before Table.',
    barUnableLink: "Toolbar cannot associate table.",
    expandContent: 'Expand row slot should be "content", please check if it is correct.',
    reqModule: 'require "{0}" module.',
    reqProp: 'Missing the necessary "{0}" parameter, which can cause error.',
    emptyProp: 'The property "{0}" is not allowed to be empty.',
    errProp: 'Unsupported parameter "{0}", possibly "{1}".',
    colRepet: 'column.{0}="{0}" is duplicated, which may make some features unusable',
    notFunc: 'method "{0}" not exist.',
    errFunc: 'The argument "{0}" is not a method',
    notValidators: 'Global validators "{0}" no existe.',
    notFormats: 'Global formats "{0}" no existe.',
    notCommands: 'Global commands "{0}" no existe.',
    notSlot: 'slot "{0}" does not exist',
    noTree: 'The tree structure does not support "{0}".',
    notProp: 'Unsupported parameters "{0}"',
    checkProp: 'The checkbox may stall when the amount of data is too large, it is recommended to set the parameter "{0}" to increase the rendering speed ',
    coverProp: 'The parameter "{1}" to "{0}" is overwritten. This may cause an error',
    delFunc: 'The function "{0}" is deprecated, please use "{1}".',
    delProp: 'The property "{0}" is deprecated, please use "{1}".',
    delEvent: 'The event "{0}" is deprecated, please use "{1}"',
    removeProp: 'The property "{0}" is deprecated and is not recommended, which may cause error.',
    errFormat: 'The global formatted content should be defined with "VXETable.formats". Mounting "formatter={0}" is not recommended.',
    notType: 'Unsupported file types "{0}"',
    notExp: "The browser does not support import / export.",
    impFields: "Import failed, please check that the field name and data format are correct.",
    treeNotImp: "Tree table does not support import.",
  },
  renderer: {
    search: "Search",
    cases: {
      equal: "Equal",
      unequal: "Not equal",
      gt: "Greater than",
      ge: "Greater than or equal",
      lt: "Less than",
      le: "Less than or equal",
      begin: "Beginning is",
      notbegin: "Beginning is not",
      endin: "End is",
      notendin: "End is not",
      include: "Include",
      exclude: "Exclusive",
      between: "Betweenness",
      custom: "Custom filter",
      insensitive: "Case insensitive",
      isSensitive: "Case sensitive",
    },
    combination: {
      menus: {
        clearSort: "Clear sort",
        sortAsc: "Ascending order",
        sortDesc: "Descending order",
        fixedColumn: "Fixed column",
        fixedGroup: "Fixed group",
        cancelFixed: "Clear fixed",
        fixedLeft: "Fixed the left",
        fixedRight: "Fixed the right",
        clearFilter: "Clear filter",
        textOption: "Text filter",
        numberOption: "Number filter",
      },
      popup: {
        title: "Custom filtering",
        currColumnTitle: "Current column:",
        and: "And",
        or: "Or",
        describeHtml: "Use ? To represent a single character <br/> use * to represent any number of characters",
      },
      empty: "(Empty)",
      notData: "No data",
    },
  },
  pro: {
    area: {
      mergeErr: "The operation cannot be performed on merged cells",
      multiErr: "The operation cannot be performed on multiple selection areas",
      extendErr: "If the extended area contains merged cells, all merged cells need to be the same size",
      pasteMultiErr: "Cannot paste, copied area and pasted area of the same size are required to perform this operation",
    },
    fnr: {
      title: "Find and replace",
      findLabel: "Find",
      replaceLabel: "Replace",
      findTitle: "What to find:",
      replaceTitle: "Replace with:",
      tabs: {
        find: "Find",
        replace: "Replace",
      },
      filter: {
        re: "Regular Expression",
        whole: "Whole word",
        sensitive: "Case sensitive",
      },
      btns: {
        findNext: "Find next",
        findAll: "Find all",
        replace: "Replace",
        replaceAll: "Replace all",
        cancel: "Cancel",
      },
      header: {
        seq: "#",
        cell: "Cell",
        value: "Value",
      },
      empty: "(Empty)",
      reError: "Invalid regular expression",
      recordCount: "Found {0} cells",
      notCell: "No matching cells were found",
      replaceSuccess: "Successfully replaced {0} cells",
    },
  },
  table: {
    emptyText: "No Data",
    allTitle: "Select all / cancel",
    seqTitle: "#",
    confirmFilter: "Confirm",
    resetFilter: "Reset",
    allFilter: "All",
    sortSingle: "Click sort",
    sortAsc: "Ascending: lowest to highest",
    sortDesc: "Descending: highest to lowest",
    filter: "Enable filtering on selected columns",
    impSuccess: "Successfully imported {0} records",
    expLoading: "Exporting",
    expSuccess: "Export success",
    expFilename: "Export_{0}",
    expOriginFilename: "Export_original_{0}",
    customTitle: "Column settings",
    customAll: "All",
    customConfirm: "Confirm",
    customRestore: "Reset",
    maxFixedCol: "The maximum number of fixed columns cannot exceed {0}",
  },
  grid: {
    selectOneRecord: "Please choose at least one piece of record!",
    deleteSelectRecord: "Are you sure you want to delete the selected record?",
    removeSelectRecord: "Are you sure you want to remove the selected record?",
    dataUnchanged: "Data unchanged! ",
    delSuccess: "Successfully deleted the selected record!",
    saveSuccess: "Saved successfully!",
    operError: "Error occurred, operation failed!",
  },
  select: {
    search: "Search",
    loadingText: "Loading",
    emptyText: "No Data",
  },
  pager: {
    goto: "Go to",
    pagesize: "{0}/page",
    total: "Total {0} record",
    pageClassifier: "",
    homePage: "Home",
    homePageTitle: "Home page",
    prevPage: "Previous page",
    prevPageTitle: "Previous page",
    nextPage: "next page",
    nextPageTitle: "next page",
    prevJump: "Jump previous page",
    prevJumpTitle: "Jump previous page",
    nextJump: "Jump next page",
    nextJumpTitle: "Jump next page",
    endPage: "End page",
    endPageTitle: "End",
  },
  alert: {
    title: "Message notification",
  },
  button: {
    confirm: "Confirm",
    cancel: "Cancel",
  },
  import: {
    modes: {
      covering: "Covering",
      insert: "Insert",
    },
    impTitle: "Import data",
    impFile: "Filename",
    impSelect: "Select file",
    impType: "File type",
    impOpts: "Settings",
    impConfirm: "Import",
    impCancel: "Cancel",
  },
  export: {
    types: {
      csv: "CSV (Comma separated) (*.csv)",
      html: "Web Page (*.html)",
      xml: "XML Data(*.xml)",
      txt: "Text (Tab delimited) (*.txt)",
      xls: "Excel 97-2003 Workbook(*.xls)",
      xlsx: "Excel Workbook (*.xlsx)",
      pdf: "PDF (*.pdf)",
    },
    modes: {
      current: "Current data (data of current page)",
      selected: "Selected data (data selected on the current page)",
      all: "Full data (including all paging data)",
    },
    printTitle: "Print data",
    expTitle: "Export data",
    expName: "Filename",
    expNamePlaceholder: "Please enter filename",
    expSheetName: "Title",
    expSheetNamePlaceholder: "Please enter a title",
    expType: "Save the type",
    expMode: "Select data",
    expCurrentColumn: "All the field",
    expColumn: "Select field",
    expOpts: "Settings",
    expOptHeader: "Header",
    expHeaderTitle: "Do I need a header",
    expOptFooter: "Footer",
    expFooterTitle: "Do you need the footer table",
    expOptColgroup: "Group header",
    expColgroupTitle: "If it exists, headers with grouping structure are supported",
    expOptMerge: "Merge",
    expMergeTitle: "If it exists, cells with merged structures are supported",
    expOptAllExpand: "Expand nodes",
    expAllExpandTitle: "If it exists, all data with tree structure can be expanded",
    expOptUseStyle: "Styles",
    expUseStyleTitle: "If it exists, cells with styles are supported",
    expOptOriginal: "Source data",
    expOriginalTitle: "If it is source data, import into the table is supported",
    expPrint: "Print",
    expConfirm: "Export",
    expCancel: "Cancel",
  },
  modal: {
    zoomIn: "Maximization",
    zoomOut: "Reduction",
    close: "Close",
  },
  form: {
    folding: "Folding",
    unfolding: "Unfolding",
  },
  toolbar: {
    import: "Import",
    export: "Export",
    print: "Printing",
    refresh: "Refresh",
    zoomIn: "Full screen",
    zoomOut: "Reduction",
    custom: "Column settings",
    customAll: "All",
    customConfirm: "Confirm",
    customRestore: "Reset",
    fixedLeft: "Fixed on the left side",
    fixedRight: "Fixed on the right side",
    cancelfixed: "Unfixed",
  },
  input: {
    date: {
      m1: "January",
      m2: "February",
      m3: "March",
      m4: "April",
      m5: "May",
      m6: "June",
      m7: "July",
      m8: "August",
      m9: "September",
      m10: "October",
      m11: "November",
      m12: "December",
      quarterLabel: "{0}",
      monthLabel: "{0}",
      dayLabel: "{1} {0}",
      labelFormat: {
        date: "dd/MM/yyyy",
        time: "HH:mm:ss",
        datetime: "yyyy-MM-dd HH:mm:ss",
        week: "[Week] WW, yyyy",
        month: "MM/yyyy",
        quarter: "[Quarter] q, yyyy",
        year: "yyyy",
      },
      weeks: {
        w: "Week",
        w0: "Sun",
        w1: "Mon",
        w2: "Tue",
        w3: "Wed",
        w4: "Thu",
        w5: "Fri",
        w6: "Sat",
      },
      months: {
        m0: "Jan",
        m1: "Feb",
        m2: "Mar",
        m3: "Apr",
        m4: "May",
        m5: "Jun",
        m6: "Jul",
        m7: "Aug",
        m8: "Sep",
        m9: "Oct",
        m10: "Nov",
        m11: "Dec",
      },
      quarters: {
        q1: "First quarter",
        q2: "Second quarter",
        q3: "Third quarter",
        q4: "Fourth quarter",
      },
    },
  },
};
