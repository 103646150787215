<template>
    <el-row :gutter="10">
        <el-divider></el-divider>
        <div style="display:flex;">
            <el-button-group>
                <el-button type="primary" @click="add1">{{ $t("lang.增加") }}</el-button>
                <el-button type="info" @click="edit1">{{ $t("lang.编辑") }}</el-button>
                <el-button type="danger" @click="del1">{{ $t("lang.删除") }}</el-button>
            </el-button-group>
        </div>
        <vxe-table style="margin-top: 5px;" ref="table1" :loading="loading1" :data="tableData1" border :row-config="{ isCurrent: true, isHover: true }" @current-change="({ row }) => { table1_currentRow = row }">
            <vxe-column field="projectName" :title="$t('lang.工程名称')"></vxe-column>
            <vxe-column field="projectAddress" :title="$t('lang.工程地址')"></vxe-column>
            <vxe-column field="beginDate" :title="$t('lang.开始日期')"></vxe-column>
            <vxe-column field="endDate" :title="$t('lang.结束日期')"></vxe-column>
            <vxe-column width="100" :title="$t('lang.操作')">
                <template #default="{ row }">
                    <vxe-button mode="text" status="primary" icon="vxe-icon-eye-fill" circle @click="handleViewProject(row)">查看</vxe-button>
                </template>
            </vxe-column>
        </vxe-table>
        <vxe-pager align="left" @page-change="load1" :page-size.sync="searchObj1.pageSize" :current-page.sync="searchObj1.currentPage" :total="searchObj1.total" :layouts="['PrevPage', 'Jump', 'PageCount', 'NextPage', 'Total']">
        </vxe-pager>
        <el-dialog :visible.sync="visible1" :close-on-click-modal="false" :show-close="false" width="30%">

            <template #title>
                <div style="border-bottom:1px solid #DCDFE6;"><i class="el-icon-s-help" style="color:#409EFF"></i>{{
                    $t("lang.工程信息") }}</div>
            </template>

            <template #footer>
                <div style="display:flex;flex-direction:row-reverse">
                    <el-button type="primary" style="margin-left:10px;" @click="submit1">{{ $t("lang.保存") }}</el-button>
                    <el-button type="info" @click="visible1 = false">{{ $t("lang.取消") }}</el-button>
                </div>
            </template>
            <el-form :model="model1" ref="form1" label-width="150px" label-position="left" :rules="rules1">
                <el-form-item :label="$t('lang.工程名称')" prop="projectName">
                    <el-input v-model="model1.projectName"></el-input>
                </el-form-item>
                <el-form-item :label="$t('lang.工程地址')" prop="projectAddress">
                    <el-input v-model="model1.projectAddress"></el-input>
                </el-form-item>
                <el-form-item :label="$t('lang.开始时间')" prop="beginDate">
                    <el-date-picker v-model="model1.beginDate" type="datetime" :placeholder="$t('lang.选择日期')" style="width:100%">
                    </el-date-picker>
                </el-form-item>
                <el-form-item :label="$t('lang.结束时间')" prop="endDate">
                    <el-date-picker v-model="model1.endDate" type="datetime" :placeholder="$t('lang.选择日期')" style="width:100%">
                    </el-date-picker>
                </el-form-item>

            </el-form>
        </el-dialog>
    </el-row>
</template>

<script>
import Vue from "vue";

// 引入样式
import "vue-easytable/libs/theme-default/index.css";
// 引入组件库
import VueEasytable from "vue-easytable";


Vue.use(VueEasytable);

export default {
    data() {
        let that = this;
        return {
            visible1: false,
            searchObj1: {
                currentPage: 1,
                pageSize: 10,
                total: 0,
            },
            loading1: false,
            model1: {},
            rules1: {
                projectName: [{ required: true, message: that.$t("lang.请输入工程名称"), trigger: "blur" }],
                projectAddress: [{ required: true, message: that.$t("lang.请输入工程地址"), trigger: "blur" }],
                beginDate: [{ required: true, message: that.$t("lang.请输入开始日期"), trigger: "blur" }],
                endDate: [{ required: true, message: that.$t("lang.请输入结束日期"), trigger: "blur" }],
            },
            tableData1: [],
            table1_currentRow: null,
            suggestData: [{ value: that.$t("lang.表层") }, { value: that.$t("lang.中上") }, { value: that.$t("lang.中") }, { value: that.$t("lang.中下") }, { value: that.$t("lang.底层") }, { value: that.$t("lang.环境") }],
            myCompanySensor: [],
        };
    },
    methods: {
        handleViewProject(row) {
            let that = this;
            that.$router.push("WXCW81ProjectView/" + row.id);
        },
        add1() {
            let that = this;
            that.visible1 = true;
            that.model1 = {};
            if (that.$refs["form1"]) {
                that.$refs["form1"].resetFields();
            }
        },
        edit1() {
            let that = this;
            if (that.$refs["form1"]) {
                that.$refs["form1"].resetFields();
            }

            if (that.table1_currentRow) {
                that.visible1 = true;
                that.axios.post("WXCW81/GetGCGL", { onlyInt: that.table1_currentRow.id }).then((response) => {
                    that.model1 = response.data.data;
                });
            } else {
                that.$message({ type: "error", message: that.$t("lang.请选择项目") });
            }
        },
        del1() {
            let that = this;
            if (that.table1_currentRow) {
                that.$confirm(that.$t("lang.确定要删除吗"))
                    .then(function () {
                        that.axios.post("WXCW81/DelGCGL", { onlyInt: that.table1_currentRow.id }).then(function () {
                            that.$message({ type: "success", message: that.$t("lang.删除成功") });
                            that.load1();
                        });
                    })
                    .catch(() => { });
            } else {
                that.$message({ type: "error", message: that.$t("lang.请选择项目") });
            }
        },
        load1() {
            let that = this;
            that.loading1 = true;
            that.axios.post("WXCW81/GetMyProject", that.searchObj1).then(function (response) {
                that.loading1 = false;
                that.tableData1 = response.data.data.data;
                that.searchObj1.total = response.data.data.total;
            });
        },
        submit1() {
            let that = this;
            that.$refs["form1"].validate((valid) => {
                if (valid) {
                    that.axios.post("WXCW81/SaveGCGL", that.model1).then((response) => {
                        if (response.data.code == 101) {
                            that.visible1 = false;
                            that.$message({ type: "success", message: that.$t("lang.保存成功") });
                            that.load1();
                        }
                    });
                }
            });
        },
        getUserSensorList() {
            let that = this;
            that.axios.post("WXCW81/GetUserSensorList", {}).then((response) => {
                that.myCompanySensor = response.data.data;
            });
        },
    },
    mounted() {
        let that = this;
        that.load1();
        that.getUserSensorList();
    },
};
</script>

<style scoped></style>