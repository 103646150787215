<template>
    <div>
        <el-divider></el-divider>
        <div style="display:flex;flex-direction:row;margin-top: 1%;margin-bottom:1%">
            <el-button-group>
                <el-button type="primary" @click="add">{{ $t("lang.增加") }}</el-button>
                <el-button type="info" @click="edit">{{ $t("lang.编辑") }}</el-button>
                <el-button type="danger" @click="del">{{ $t("lang.删除") }}</el-button>
            </el-button-group>
        </div>
        <vxe-table ref="table" border show-overflow :data="tableData" :loading="loading" :loading-config="{ icon: 'vxe-icon-indicator roll', text: $t('lang.加载中') }" :row-config="{ isCurrent: true, isHover: true }" @current-change="({ row }) => { table_currentRow = row }">>
            <vxe-column field="name" :title="$t('lang.上下限名称')"></vxe-column>
            <vxe-column field="wxcW81_WXCWSensor.sensorName" :title="$t('lang.传感器')"></vxe-column>
            <vxe-column field="baoJingJianGe" :title="$t('lang.报警间隔') + '(' + $t('lang.分钟') + ')'" align="right" width="120"></vxe-column>
            <vxe-column field="shangXian" :title="$t('lang.上限值') + '(℃)'" align="right" width="95"></vxe-column>
            <vxe-column field="xiaXian" :title="$t('lang.下限值') + '(℃)'" align="right" width="95"></vxe-column>
            <vxe-column field="remark" :title="$t('lang.备注')"></vxe-column>
            <vxe-column field="createTime" :title="$t('lang.创建时间')" width="150"></vxe-column>
            <vxe-column width="130" :title="$t('lang.操作')" align="center">
                <template #default="{ row }">
                    <template v-if="row.notReadCount == 0">
                        <vxe-button mode="text" status="primary" circle @click="recordGetList(row)">{{ $t("lang.报警记录") }}</vxe-button>
                    </template>
                    <template v-else>
                        <el-badge :value="row.notReadCount" class="item">
                            <vxe-button mode="text" status="primary" circle @click="recordGetList(row)">{{ $t("lang.报警记录") }}</vxe-button>
                        </el-badge>
                    </template>
                </template>
            </vxe-column>
        </vxe-table>
        <vxe-pager align="left" :page-size.sync="searchObj.pageSize" :current-page.sync="searchObj.currentPage" :total="searchObj.total" :layouts="['PrevPage', 'Jump', 'PageCount', 'NextPage', 'Total']" @page-change="getList">
        </vxe-pager>
        <el-dialog :visible.sync="visible" :close-on-click-modal="false" width="40%" :show-close="false">
            <template #title>
                <div style="border-bottom:1px solid #DCDFE6;"><i class="el-icon-s-help" style="color:#409EFF"></i>{{ $t("lang.上下限报警") }}</div>
            </template>
            <el-form :model="model" ref="form" label-width="150px" :rules="rules">
                <el-form-item :label="$t('lang.上下限名称')" prop="name">
                    <el-input v-model="model.name"></el-input>
                </el-form-item>
                <el-form-item :label="$t('lang.工程名称')" prop="projectId">
                    <el-select v-model="model.projectId" style="width:100%" :placeholder="$t('lang.请选择')">
                        <el-option v-for="(item, index) in myProjectList" :key="index" :label="item.projectName" :value="item.id">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item :label="$t('lang.报警间隔')" prop="baoJingJianGe">
                    <el-input v-model.number="model.baoJingJianGe"><template #append>{{ $t('lang.分钟') }}</template></el-input>
                </el-form-item>
                <el-form-item :label="$t('lang.传感器')" prop="sensorId">
                    <el-col :span="24">
                        <el-select v-model="model.sensorId" :placeholder="$t('lang.请选择')" style="width:100%">
                            <el-option v-for="item in options" :key="item.id" :label="item.sensorName" :value="item.id">
                            </el-option>
                        </el-select>
                    </el-col>
                </el-form-item>
                <el-form-item :label="$t('lang.上限值')" prop="shangXian">
                    <el-input v-model.number="model.shangXian" type="number"><template #append>℃</template></el-input>
                </el-form-item>
                <el-form-item :label="$t('lang.下限值')" prop="xiaXian">
                    <el-input v-model.number="model.xiaXian" type="number"><template #append>℃</template></el-input>
                </el-form-item>
                <el-form-item :label="$t('lang.备注')">
                    <el-input v-model="model.remark"></el-input>
                </el-form-item>
            </el-form>
            <template #footer>
                <div style="display:flex;flex-direction:row-reverse">
                    <el-button type="primary" style="margin-left:10px;" @click="save">{{ $t("lang.保存") }}</el-button>
                    <el-button type="info" @click="visible = false">{{ $t("lang.取消") }}</el-button>
                </div>
            </template>
        </el-dialog>
        <el-dialog :visible.sync="dialogVisible" :close-on-click-modal="false" width="69%" :show-close="false" top="8vh">
            <template #title>
                <div style="border-bottom:1px solid #DCDFE6;"> <i class="el-icon-s-help" style="color:#409EFF"></i>{{ wxcW81_BJ_SXXBJName }}</div>
            </template>
            <div style="margin-bottom:1%">
                <el-button type="primary" @click="updateAll">{{ $t('lang.全部标为已读') }}</el-button>
            </div>
            <vxe-table ref="alarmTable" border :data="alarmTableData" :row-style="rowStyle" :loading="alarmLoading" :loading-config="{ icon: 'vxe-icon-indicator roll', text: $t('lang.加载中') }" :style="{ 'max-height': '80vh', 'overflow-y': 'hidden' }">
                <vxe-column field="sensorName" :title="$t('lang.传感器')"></vxe-column>
                <vxe-column field="dataTime" :title="$t('lang.传感器时间')" width="160"></vxe-column>
                <vxe-column field="temperature" :title="$t('lang.传感器温度') + '℃'" align="right" width="128"></vxe-column>
                <vxe-column field="wxcW81_BJ_SXXBJ.shangXian" :title="$t('lang.上限值') + '℃'" align="right" width="95"></vxe-column>
                <vxe-column field="wxcW81_BJ_SXXBJ.xiaXian" :title="$t('lang.下限值') + '℃'" align="right" width="95"></vxe-column>
                <vxe-column field="createTime" :title="$t('lang.创建时间')" width="160"></vxe-column>
            </vxe-table>
            <vxe-pager align="left" :page-size.sync="alarmObj.pageSize" :current-page.sync="alarmObj.currentPage" :total="alarmObj.total" :layouts="['PrevPage', 'Jump', 'PageCount', 'NextPage', 'Total']" @page-change="recordGetList1()">
            </vxe-pager>
            <template #footer>
                <el-button type="info" style="margin-left:10px;" @click="dialogVisible = false">{{ $t("lang.关闭") }}</el-button>
            </template>
        </el-dialog>
    </div>
</template>

<script>
import Vue from "vue";
// 引入样式
import "vue-easytable/libs/theme-default/index.css";
// 引入组件库sensorNumber
import VueEasytable from "vue-easytable";
Vue.use(VueEasytable);
export default {
    data() {
        let that = this;
        return {
            options: [],
            myProjectList: [],
            tableData: [],
            alarmTableData: [],
            table_currentRow: null,
            loading: false,
            alarmLoading: false,
            visible: false,
            dialogVisible: false,
            dialogType: "",
            wxcW81_BJ_SXXBJName: "",
            searchObj: {
                currentPage: 1,
                pageSize: 10,
                total: 0,
            },
            alarmObj: {
                currentPage: 1,
                pageSize: 10,
                total: 0,
            },
            model: {
                name: "",
                projectId: "",
                baoJingJianGe: 30,
                sensorId: "",
                shangXian: 0,
                xiaXian: 0,
                remark: "",
            },
            rules: {
                name: [
                    {
                        required: true,
                        message: that.$t("lang.请输入上下限名称"),
                        trigger: "change",
                    },
                ],
                projectId: [
                    {
                        required: true,
                        message: that.$t("lang.请选择工程名称"),
                        trigger: "change",
                    },
                ],
                sensorId: [
                    {
                        required: true,
                        message: that.$t("lang.请选择设备"),
                        trigger: "change",
                    },
                ],
                baoJingJianGe: [
                    {
                        required: true,
                        message: that.$t("lang.请输入报警间隔"),
                        trigger: "change",
                    },
                    { type: "number", message: that.$t("lang.报警间隔必须为数字值") },
                ],
                shangXian: [
                    {
                        required: true,
                        message: that.$t("lang.请输入上限值"),
                        trigger: "change",
                    },
                    { type: "number", message: that.$t("lang.上限值必须为数字值") },
                ],
                xiaXian: [
                    {
                        required: true,
                        message: that.$t("lang.请输入下限值"),
                        trigger: "change",
                    },
                    { type: "number", message: that.$t("lang.下限值必须为数字值") },
                ],
            },
        };
    },
    methods: {
        add() {
            let that = this;
            that.dialogType = "add"; // 设置为添加操作
            that.model = { baoJingJianGe: 30 };
            if (that.$refs["form"]) {
                that.$refs["form"].resetFields();
            }
            that.visible = true;
        },
        submit1() {
            let that = this;
            that.$refs["form"].validate((valid) => {
                if (valid) {
                    that.axios
                        .post("WXCW81/SXXBJ_AddModel", that.model)
                        .then((response) => {
                            if (response.data.code == 101) {
                                that.visible = false;
                                that.$message({
                                    type: "success",
                                    message: that.$t("lang.保存成功"),
                                });
                                that.getList();
                            }
                        });
                }
            });
        },
        del() {
            let that = this;
            if (that.table_currentRow !== null) {
                that.$confirm(that.$t("lang.确定要删除吗")).then(function () {
                    that.axios
                        .post("WXCW81/SXXBJ_DelModel", {
                            onlyInt: that.table_currentRow.id,
                        })
                        .then(function (response) {
                            if (response.data.code == 101) {
                                that.$message({
                                    type: "success",
                                    message: that.$t("lang.删除成功"),
                                });
                                that.getList();
                            }
                        });
                });
            } else {
                that.$message({
                    type: "error",
                    message: that.$t("lang.请选择一条记录"),
                });
            }
        },
        edit() {
            let that = this;
            that.dialogType = "edit";
            if (that.table_currentRow !== null) {
                that.visible = true;
                that.axios
                    .post("WXCW81/SXXBJ_GetModel", { onlyInt: that.table_currentRow.id })
                    .then((response) => {
                        that.model = response.data.data;
                    });
            } else {
                that.$message({
                    type: "error",
                    message: that.$t("lang.请选择一条记录"),
                });
            }
        },
        submit2() {
            let that = this;
            that.$refs["form"].validate((valid) => {
                if (valid) {
                    that.axios
                        .post("WXCW81/SXXBJ_UpdateModel", that.model)
                        .then((response) => {
                            if (response.data.code == 101) {
                                that.visible = false;
                                that.$message({
                                    type: "success",
                                    message: that.$t("lang.保存成功"),
                                });
                                that.getList();
                            }
                        });
                }
            });
        },
        getList() {
            let that = this;
            that.table_currentRow = null;
            that.loading = true;
            that.axios
                .post("WXCW81/SXXBJ_GetList", {
                    currentPage: that.searchObj.currentPage,
                    pageSize: that.searchObj.pageSize,
                    total: that.searchObj.total,
                })
                .then((response) => {
                    that.loading = false;
                    that.tableData = response.data.data.data;
                    that.searchObj.total = response.data.data.total;
                });
        },
        save() {
            let that = this;
            if (that.dialogType == "add") {
                that.submit1();
            } else if (that.dialogType == "edit") {
                that.submit2();
            }
        },
        getUserSensorList() {
            let that = this;
            that.axios.post("WXCW81/getUserSensorList", {}).then((response) => {
                that.options = response.data.data;
            });
        },
        getMyProject() {
            let that = this;
            that.axios
                .post("WXCW81/GetMyProject", {
                    pageSize: 100000,
                    currentPage: 1,
                    total: 0,
                })
                .then((response) => {
                    that.myProjectList = response.data.data.data;
                });
        },
        recordGetList(row) {
            let that = this;
            that.alarmObj.currentPage = 1;
            that.wxcW81_BJ_SXXBJName = row.name;
            that.dialogVisible = true;
            that.axios
                .post("WXCW81/SXXBJ_Record_GetList", {
                    pageSize: that.alarmObj.pageSize,
                    currentPage: that.alarmObj.currentPage,
                    total: that.alarmObj.total,
                    docId: row.id,
                })
                .then((response) => {
                    that.alarmTableData = response.data.data.data;
                    that.alarmObj.total = response.data.data.total;
                });
        },
        recordGetList1() {
            let that = this;
            that.dialogVisible = true;
            that.alarmLoading = true;
            that.axios
                .post("WXCW81/SXXBJ_Record_GetList", {
                    pageSize: that.alarmObj.pageSize,
                    currentPage: that.alarmObj.currentPage,
                    total: that.alarmObj.total,
                    docId: that.table_currentRow.id,
                })
                .then((response) => {
                    that.alarmLoading = false;
                    that.alarmTableData = response.data.data.data;
                    that.alarmObj.total = response.data.data.total;
                });
        },
        updateAll() {
            let that = this;
            const requestData = {
                pageSize: that.alarmObj.pageSize,
                currentPage: that.alarmObj.currentPage,
                total: that.alarmObj.total,
                docId: that.table_currentRow.id,
            };
            that.alarmLoading = true;
            that.axios
                .post("WXCW81/SXXBJ_Record_UpdateAllWeiDu", requestData)
                .then((response) => {
                    if (response.data.code == 101) {
                        that.axios
                            .post("WXCW81/SXXBJ_Record_GetList", requestData)
                            .then((response) => {
                                that.alarmLoading = false;
                                that.alarmTableData = response.data.data.data;
                                that.alarmObj.total = response.data.data.total;
                            });
                    }
                });
        },
        rowStyle({ row }) {
            if (row.readFlag == false) {
                return {
                    fontWeight: "bold",
                };
            }
            return "";
        },
    },

    mounted() {
        let that = this;
        that.getUserSensorList();
        that.getMyProject();
        that.getList();
    },
};
</script>

<style scoped>
.item {
    margin-top: 8px;
    /* margin-right: 40px; */
}
</style>