<template>
    <div class="sumjcenter">
        <el-image :src="'images/gzh/gzh.png'" v-if="gzh_qrcode" class="sumjcenter1"></el-image>
        <el-alert class="sumjcenter1" v-if="errorVisible" :title="errorText" type="error" center show-icon :closable="false"></el-alert>
        <van-dialog v-model="dialogVisible" :before-close="handleBeforeClose">
            <van-field v-model="remark" label="备注" placeholder="请输入备注" />
        </van-dialog>

    </div>
</template>

<script>
import Vue from "vue";
import Vant from "vant";
import "vant/lib/index.css";

Vue.use(Vant);
export default {
    data() {
        return {
            errorVisible: false,
            errorText: "",
            dialogVisible: false,
            remark: "",
            view_OpenIdDevice: null,
            gzh_qrcode: false
        };
    },
    methods: {
        redirectFromSerialNumber() {
            let that = this;
            //判断有没有备注。没有备注则提示用户填写备注
            if (that.view_OpenIdDevice.DeviceType == "WXCW81") {
                window.location.href = "Wap_WXCW81_Operate/" + that.view_OpenIdDevice.SerialNumber;
            } else if (that.view_OpenIdDevice.DeviceType == "LLZ100") {
                window.location.href = "Wap_LLZ100_Operate/" + that.view_OpenIdDevice.SerialNumber + "/0";
            } else if (that.view_OpenIdDevice.DeviceType == "WXCW01") {
                window.location.href = "WapWXCW01Operate/" + that.view_OpenIdDevice.SerialNumber;
            } else if (that.view_OpenIdDevice.DeviceType.indexOf("WXCW") > -1) {
                window.location.href = "WapWXCW03Operate/" + that.view_OpenIdDevice.SerialNumber;
            } else if (that.view_OpenIdDevice.DeviceType == "PRINT1") {
                window.location.href = "WapPRINT1Operate/" + that.view_OpenIdDevice.SerialNumber;
            } else if (that.view_OpenIdDevice.DeviceType == "HDWY01") {
                window.location.href = "WapHDWY01Operate?serialNumber=" + that.view_OpenIdDevice.SerialNumber;
            } else if (that.view_OpenIdDevice.DeviceType == "WDD001") {
                window.location.href = "WapWDD001Operate/" + that.view_OpenIdDevice.SerialNumber;
            } else if (that.view_OpenIdDevice.DeviceType == "LQY108") {
                window.location.href = "WapLQY108Operate/" + that.view_OpenIdDevice.SerialNumber;
            } else if (that.view_OpenIdDevice.DeviceType == "CZCX01") {
                window.location.href = "WapCZCX01Operate/" + that.view_OpenIdDevice.SerialNumber;
            } else if (that.view_OpenIdDevice.DeviceType == "JSY002") {
                window.location.href = "WapJSY002Operate/" + that.view_OpenIdDevice.SerialNumber;
            } else if (that.view_OpenIdDevice.DeviceType == "ZRD008") {
                window.location.href = "WapZRD008Operate/" + that.view_OpenIdDevice.SerialNumber;
            } else if (that.view_OpenIdDevice.DeviceType == "HWSY02") {
                window.location.href = "WapHWSY02Operate/" + that.view_OpenIdDevice.SerialNumber;
            } else if (that.view_OpenIdDevice.DeviceType == "RHD008") {
                window.location.href = "WapRHD008Operate/" + that.view_OpenIdDevice.SerialNumber;
            } else if (that.view_OpenIdDevice.DeviceType == "PZD801") {
                window.location.href = "/D?a=1";
            } else if (that.view_OpenIdDevice.DeviceType == "CLY008") {
                window.location.href = "/D?a=10";
            } else if (that.view_OpenIdDevice.DeviceType == "LLZ001") {
                window.location.href = "WapLLZ001Operate/" + that.view_OpenIdDevice.SerialNumber;
            } else if (that.view_OpenIdDevice.DeviceType == "LYT001") {
                //留样桶，这里是来料的管理页面。直接跳转到MaterielEdit并且在跳转之前生成一条记录。它的IsDelete为true
                that.axios
                    .post("Materiel/CreateMateriel", {
                        onlyText: that.view_OpenIdDevice.SerialNumber,
                    })
                    .then(function (response) {
                        window.location.href = "WapLYT001Operate/" + response.data.data.id;
                    });
            } else if (that.view_OpenIdDevice.DeviceType == "KSY001") {
                window.location.href = "Old/WAP_KSY001/Operate?SerialNumber=" + that.view_OpenIdDevice.SerialNumber + "&OpenId=" + that.view_OpenIdDevice.OpenId + "&DeviceType=KSY001";
            } else if (that.view_OpenIdDevice.DeviceType == "RSL001") {
                window.location.href = "Old/WAP_RSL001/Operate?SerialNumber=" + that.view_OpenIdDevice.SerialNumber + "&OpenId=" + that.view_OpenIdDevice.OpenId + "&DeviceType=RSL001";
            } else if (that.view_OpenIdDevice.DeviceType == "YSY001") {
                window.location.href = "Old/WAP_YSY001/Operate?SerialNumber=" + that.view_OpenIdDevice.SerialNumber + "&OpenId=" + that.view_OpenIdDevice.OpenId + "&DeviceType=YSY001";
            } else {
                //未开发页面
                that.errorText = that.view_OpenIdDevice.DeviceType + "开发中...";
                that.errorVisible = true;
            }
        },
        handleBeforeClose(action, done) {
            let that = this;
            if (that.remark) {
                that.axios
                    .post("Base_Device/SaveDeviceRemark", {
                        serialNumber: that.view_OpenIdDevice.SerialNumber,
                        remark: that.remark,
                    })
                    .then(function () {
                        done();
                        that.redirectFromSerialNumber();
                    })
                    .catch(function () {
                        that.$notify("保存异常");
                    });
            } else {
                that.$notify("请输入备注");
                done(false);
            }
        },
    },
    mounted() {
        let that = this;

        that.axios
            .post("Login/AutoLogin", {
                openId: that.$route.query.openId,
                code: that.$route.query.code,
                serialNumber: that.$route.query.serialNumber, //后面带序列号的
                redirect: that.$route.query.redirect, //后面带直接跳转页面的
                signNumber: that.$route.query.signNumber, //试块码
                customerSignNumber: that.$route.query.customerSignNumber, //客户试块码
                sensorNumber: that.$route.query.sensorNumber, //无线测温仪传感器
            })
            .then(function (response) {
                if (response.data.code == 101) {
                    window.localStorage.setItem("token", response.data.token);
                    let obj = JSON.parse(response.data.data); //注意这里属性都是大写开头
                    //遇到停在http://gzh.bjlcyq.cn/AutoLogin?customerSignNumber=页面不动，在后面加&openId=虚拟已经获取到openId操作
                    if (obj.SerialNumber) {
                        that.view_OpenIdDevice = obj;
                        if (!obj.Remark || obj.Remark == "扫码绑定") {
                            that.dialogVisible = true;
                        } else {
                            //直接转向页面
                            that.redirectFromSerialNumber();
                        }
                    } else if (obj.Redirect) {
                        window.location.href = obj.Redirect;
                    } else {
                        //其它作用的码
                    }
                }
                else if (response.data.code == 501) {
                    that.gzh_qrcode = true;
                }
                else {
                    that.errorText = response.data.data;
                    that.errorVisible = true;
                }
            });
    },
};
</script>

<style scoped>
.sumjcenter {
    vertical-align: middle;
    height: 100%;
}

.sumjcenter1 {
    margin-top: 50%;
}
</style>