<template>
    <div style="margin-top: 4px;">
        <el-divider></el-divider>
        <vxe-table ref="table" :loading="loading" border show-overflow @edit-closed="editClosed" :row-config="{ isHover: true }" :data="tableData" :tooltip-config="tooltipConfig" :edit-config="{ trigger: 'dblclick', mode: 'cell', autoClear: true, beforeEditMethod: beforeEditMethod, }">
            <vxe-column field="serialNumber" :title="$t('lang.序列号')" width="85">
                <template #edit="{ row }">
                    <vxe-input v-model="row.serialNumber" type="number"></vxe-input>
                </template>
            </vxe-column>
            <vxe-column field="dataTime" :title="$t('lang.时间')" width="150">
            </vxe-column>
            <vxe-column field="t1" title="t1(℃)" :edit-render="{}" align="right">
                <template #edit="{ row }">
                    <vxe-input v-model="row.t1" type="number"></vxe-input>
                </template>
                <template #default="{ row }">{{ row.t1 }}
                    <vxe-button v-if="row.t1_SensorNumber" mode="text" status="success" icon="vxe-icon-pct-full" circle @click="show_sensorNumber_qrCode(row.t1_SensorNumber)"></vxe-button>
                </template>
            </vxe-column>
            <vxe-column field="t2" title="t2(℃)" :edit-render="{}" align="right">
                <template #edit="{ row }">
                    <vxe-input v-model="row.t2" type="number"></vxe-input>
                </template>
                <template #default="{ row }">{{ row.t2 }}
                    <vxe-button v-if="row.t2_SensorNumber" mode="text" status="success" icon="vxe-icon-pct-full" circle></vxe-button>
                </template>
            </vxe-column>
            <vxe-column field="t3" title="t3(℃)" :edit-render="{}" align="right">
                <template #edit="{ row }">
                    <vxe-input v-model="row.t3" type="number"></vxe-input>
                </template>
                <template #default="{ row }">{{ row.t3 }}
                    <vxe-button v-if="row.t3_SensorNumber" mode="text" status="success" icon="vxe-icon-pct-full" circle></vxe-button>
                </template>
            </vxe-column>
            <vxe-column field="t4" title="t4(℃)" :edit-render="{}" align="right">
                <template #edit="{ row }">
                    <vxe-input v-model="row.t4" type="number"></vxe-input>
                </template>
                <template #default="{ row }">{{ row.t4 }}
                    <vxe-button v-if="row.t4_SensorNumber" mode="text" status="success" icon="vxe-icon-pct-full" circle></vxe-button>
                </template>
            </vxe-column>
            <vxe-column field="t5" title="t5(℃)" :edit-render="{}" align="right">
                <template #edit="{ row }">
                    <vxe-input v-model="row.t5" type="number"></vxe-input>
                </template>
                <template #default="{ row }">{{ row.t5 }}
                    <vxe-button v-if="row.t5_SensorNumber" mode="text" status="success" icon="vxe-icon-pct-full" circle></vxe-button>
                </template>
            </vxe-column>
            <vxe-column field="t6" title="t6(℃)" :edit-render="{}" align="right">
                <template #edit="{ row }">
                    <vxe-input v-model="row.t6" type="number"></vxe-input>
                </template>
                <template #default="{ row }">{{ row.t6 }}
                    <vxe-button v-if="row.t6_SensorNumber" mode="text" status="success" icon="vxe-icon-pct-full" circle></vxe-button>
                </template>
            </vxe-column>
            <vxe-column field="t7" title="t7(℃)" :edit-render="{}" align="right">
                <template #edit="{ row }">
                    <vxe-input v-model="row.t7" type="number"></vxe-input>
                </template>
                <template #default="{ row }">{{ row.t7 }}
                    <vxe-button v-if="row.t7_SensorNumber" mode="text" status="success" icon="vxe-icon-pct-full" circle></vxe-button>
                </template>
            </vxe-column>
            <vxe-column field="t8" title="t8(℃)" :edit-render="{}" align="right">
                <template #edit="{ row }">
                    <vxe-input v-model="row.t8" type="number"></vxe-input>
                </template>
                <template #default="{ row }">{{ row.t8 }}
                    <vxe-button v-if="row.t8_SensorNumber" mode="text" status="success" icon="vxe-icon-pct-full" circle></vxe-button>
                </template>
            </vxe-column>
        </vxe-table>
        <vxe-pager :current-page.sync="page.currentPage" :page-size.sync="page.pageSize" :total="page.total" @page-change="getList" align="left" />
        <vxe-modal v-model="showQR" :width="280" :height="280" title="qrCode" className="thisCenter">
            <div style="display: flex; justify-content: center;align-items: center;">
                <vueQr :text="qrText"></vueQr>
            </div>
        </vxe-modal>
    </div>
</template>

<script>
import vueQr from "vue-qr";
export default {
    components: { vueQr },
    data() {
        let that = this;
        return {
            showQR: false,
            qrText: "",
            tableData: [],
            loading: false,
            page: {
                currentPage: 1,
                pageSize: 10,
                total: 0,
            },
            tooltipConfig: {
                enterDelay: 0,
                contentMethod: ({ column, row }) => {
                    const { field } = column;
                    if (field == "t1" || field == "t2" || field == "t3" || field == "t4" || field == "t5" || field == "t6" || field == "t7" || field == "t8") {
                        return row[`${field}_SensorNumber`] ? row[`${field}_SensorNumber`] : that.$t("lang.空");
                    }
                },
            },
        };
    },
    methods: {
        show_sensorNumber_qrCode(v) {
            let that = this;
            that.qrText = "https://bjlcyq.cn/s?c=" + v;
            that.showQR = true;
        },
        beforeEditMethod(column) {
            const disabledColumns = [
                "t1_SensorNumber",
                "t2_SensorNumber",
                "t3_SensorNumber",
                "t4_SensorNumber",
                "t5_SensorNumber",
                "t6_SensorNumber",
                "t7_SensorNumber",
                "t8_SensorNumber",
                "serialNumber",
            ];
            if (disabledColumns.includes(column.field)) {
                return false;
            }
            return true;
        },
        getList() {
            let that = this;
            that.loading = true;
            that.axios.post("WXCW81/YSSJ_GetList", that.page).then((response) => {
                that.tableData = response.data.data.data;
                that.page.total = response.data.data.total;
                that.loading = false;
            });
        },
        editClosed({ row }) {
            let that = this;
            if (row.t1 === "") row.t1 = null;
            if (row.t2 === "") row.t2 = null;
            if (row.t3 === "") row.t3 = null;
            if (row.t4 === "") row.t4 = null;
            if (row.t5 === "") row.t5 = null;
            if (row.t6 === "") row.t6 = null;
            if (row.t7 === "") row.t7 = null;
            if (row.t8 === "") row.t8 = null;
            that.axios
                .post("WXCW81/YSSJ_Update", row)
                .then((response) => {
                    if (response.data.code == 101) {
                        that.$message.success(that.$t("lang.修改成功"));
                    }
                });

        },
    },
    mounted() {
        let that = this;
        that.getList();
    },
};
</script>

<style scoped></style>