<template>
    <div>
        <el-card v-show="active == 0" :body-style="{ padding: '5px' }" v-loading="loading" style="background-color: #409EFF;">
            <van-cell-group>
                <van-cell title="序列号" :label="obj_Device.remark ? obj_Device.remark : ''">
                    {{ serialNumber }}
                </van-cell>
                <van-cell title="采集时间">
                    {{ obj_WXCW81_Result.dataTime }}
                </van-cell>
                <van-cell title="T1" value-class="primaryFont" :is-link="obj_WXCW81_Result.t1 != null" :url="obj_WXCW81_Result.t1 != null ? '/Wap_WXCW81_OneSensor?sensorNumber=' + obj_WXCW81_Result.t1_SensorNumber : ''">
                    {{ obj_WXCW81_Result.t1 ? obj_WXCW81_Result.t1 + '℃' : "-" }}
                </van-cell>
                <van-cell title="T2" value-class="primaryFont" :is-link="obj_WXCW81_Result.t2 != null" :url="obj_WXCW81_Result.t2 != null ? '/Wap_WXCW81_OneSensor?sensorNumber=' + obj_WXCW81_Result.t2_SensorNumber : ''">
                    {{ obj_WXCW81_Result.t2 ? obj_WXCW81_Result.t2 + '℃' : "-" }}
                </van-cell>
                <van-cell title="T3" value-class="primaryFont" :is-link="obj_WXCW81_Result.t3 != null" :url="obj_WXCW81_Result.t3 != null ? '/Wap_WXCW81_OneSensor?sensorNumber=' + obj_WXCW81_Result.t3_SensorNumber : ''">
                    {{ obj_WXCW81_Result.t3 ? obj_WXCW81_Result.t3 + '℃' : "-" }}
                </van-cell>
                <van-cell title="T4" value-class="primaryFont" :is-link="obj_WXCW81_Result.t4 != null" :url="obj_WXCW81_Result.t4 != null ? '/Wap_WXCW81_OneSensor?sensorNumber=' + obj_WXCW81_Result.t4_SensorNumber : ''">
                    {{ obj_WXCW81_Result.t4 ? obj_WXCW81_Result.t4 + '℃' : "-" }}
                </van-cell>
                <van-cell title="T5" value-class="primaryFont" :is-link="obj_WXCW81_Result.t5 != null" :url="obj_WXCW81_Result.t5 != null ? '/Wap_WXCW81_OneSensor?sensorNumber=' + obj_WXCW81_Result.t5_SensorNumber : ''">
                    {{ obj_WXCW81_Result.t5 ? obj_WXCW81_Result.t5 + '℃' : "-" }}
                </van-cell>
                <van-cell title="T6" value-class="primaryFont" :is-link="obj_WXCW81_Result.t6 != null" :url="obj_WXCW81_Result.t6 != null ? '/Wap_WXCW81_OneSensor?sensorNumber=' + obj_WXCW81_Result.t6_SensorNumber : ''">
                    {{ obj_WXCW81_Result.t6 ? obj_WXCW81_Result.t6 + '℃' : "-" }}
                </van-cell>
                <van-cell title="T7" value-class="primaryFont" :is-link="obj_WXCW81_Result.t7 != null" :url="obj_WXCW81_Result.t7 != null ? '/Wap_WXCW81_OneSensor?sensorNumber=' + obj_WXCW81_Result.t7_SensorNumber : ''">
                    {{ obj_WXCW81_Result.t7 ? obj_WXCW81_Result.t7 + '℃' : "-" }}
                </van-cell>
                <van-cell title="T8" value-class="primaryFont" :is-link="obj_WXCW81_Result.t8 != null" :url="obj_WXCW81_Result.t8 != null ? '/Wap_WXCW81_OneSensor?sensorNumber=' + obj_WXCW81_Result.t8_SensorNumber : ''">
                    {{ obj_WXCW81_Result.t8 ? obj_WXCW81_Result.t8 + '℃' : "-" }}
                </van-cell>
                <van-cell title="电量">
                    <el-progress text-inside :stroke-width="26" :percentage="obj_WXCW81_Result.dianLiang"></el-progress>
                </van-cell>
            </van-cell-group>
        </el-card>
        <div v-show="active == 1 && obj_WXCW81_Result.gps" id="WXCW81_ZuoBiao" style="height: 500px;"></div>
        <van-empty v-if="active == 1 && !obj_WXCW81_Result.gps" description="无坐标"></van-empty>
        <el-card v-show="active == 2" :body-style="{ padding: '5px' }">
            <van-cell-group title="设备设置">
                <van-field label="背光时长(分钟)" label-width="100px" v-model="obj_Config.lightTime" placeholder="请输入背光时长" input-align="right"></van-field>
                <van-field label="采样间隔(分钟)" label-width="100px" v-model="obj_Config.intervalTime" placeholder="请输入采样间隔" input-align="right"></van-field>
                <van-field v-model="obj_Config.companyName" readonly clearable label="对接公司名称" placeholder="请选择" @click-input="showPicker = true" input-align="right" is-link @click-right-icon="handleClear">
                    <template #right-icon>
                        <span v-show="obj_Config.companyName !== ''"><van-icon name="close" /></span>
                    </template></van-field>
                <van-popup v-model="showPicker" position="bottom">
                    <van-picker show-toolbar :columns="optionsDataType" value-key="dataText" @confirm="onConfirm" @cancel="showPicker = false" />
                </van-popup>
                <van-cell is-link :closeable="true" title="开机升级" @click="show_popup = true">{{ obj_Config.ifUpgrade ? '是' : '否' }}</van-cell>
            </van-cell-group>
            <van-button type="primary" @click="saveConfig" block>保存</van-button>
        </el-card>
        <van-tabbar v-model="active">
            <van-tabbar-item icon="home-o">主页</van-tabbar-item>
            <van-tabbar-item icon="location-o">坐标</van-tabbar-item>
            <van-tabbar-item icon="setting-o">设置</van-tabbar-item>
        </van-tabbar>
        <van-popup v-model="show_popup" position="bottom" :style="{ height: '350px' }" @open="pwd = ''">
            <van-password-input :value="pwd" :focused="false" :length="4" style="margin-top: 10px;"></van-password-input>
            <van-number-keyboard v-model="pwd" :maxlength="4" :show="true"></van-number-keyboard>
        </van-popup>
        <van-action-sheet v-model="show_action_sheet" :actions="actions" @select="onSelect" />
    </div>
</template>

<script>
import Vue from "vue";
import Vant from "vant";
import "vant/lib/index.css";
import AMapLoader from "@amap/amap-jsapi-loader";
Vue.use(Vant);
export default {
    props: ["serialNumber"],
    data() {
        return {
            pageSize: 100,
            currentPage: 1,
            optionsDataType: [],
            loading: false,
            obj_WXCW81_Result: {},
            obj_Device: {},
            obj_Config: {},
            active: 0,
            show_popup: false,
            pwd: "",
            show_action_sheet: false,
            actions: [{ name: "是" }, { name: "否" }],
            showPicker: false,
            //坐标相关
            marker: null,
            map: null,
            AMap: null,
            center: [121.32093057, 31.90087405],
        };
    },
    watch: {
        pwd(v) {
            let that = this;
            if (v == "8672") {
                that.show_popup = false;
                that.show_action_sheet = true;
            } else if (v.length == 4) {
                that.$notify({ type: "danger", message: "密码错误" });
                that.show_popup = false;
            }
        },
    },
    methods: {
        handleClear() {
            let that = this;
            that.obj_Config.companyName = ""; // 清空输入框的值
        },
        onConfirm(value) {
            let that = this;
            that.obj_Config.companyName = value.dataValue;
            that.showPicker = false;
        },
        getList() {
            let that = this;
            that.axios
                .post("Base_SystemBaseData/GetListByDataType", {
                    dataType: "接口公司名称",
                    pageSize: that.pageSize,
                    currentPage: that.currentPage,
                    total: 0,
                })
                .then(function (response) {
                    that.optionsDataType = response.data.data;
                });
        },
        onSelect(item) {
            let that = this;
            that.show_action_sheet = false;
            that.obj_Config.ifUpgrade = item.name == "是";
        },
        saveConfig() {
            let that = this;
            that.axios
                .post("WXCW/UpdateConfig", that.obj_Config)
                .then(function (response) {
                    if (response.data.code === 101) {
                        that.$notify({
                            type: "success",
                            message: "保存成功，请重启设备",
                        });
                    }
                });
        },
        load_ZuoBiao() {
            let that = this;
            window._AMapSecurityConfig = {
                securityJsCode: "79a75a2c6cf8b4efd7e0cd8a1d905496",
            };
            AMapLoader.load({ key: "d2234c0fa737a6010ec0e3480426e0c3" }).then((AMap) => {
                let arr_lng_lat = that.obj_WXCW81_Result.gps.split(",");


                that.center = [parseFloat(arr_lng_lat[0]), parseFloat(arr_lng_lat[1])];
                that.AMap = AMap;
                //转换坐标
                AMap.convertFrom(that.center, "gps", function (status, result) {
                    console.log(status, result);
                    //status：complete 表示查询成功，no_data 为查询无结果，error 代表查询错误
                    //查询成功时，result.locations 即为转换后的高德坐标系
                    if (status === "complete" && result.info === "ok") {
                        that.center = [result.locations[0].lng, result.locations[0].lat];
                        that.map = new AMap.Map("WXCW81_ZuoBiao", {
                            center: that.center,
                            zoom: 11
                        });
                        that.marker = new AMap.Marker({
                            position: that.center,
                        });
                        that.map.add(that.marker);
                    }
                });

            });
        }
    },
    mounted() {
        document.title = "无线测温仪";
        let that = this;
        that.getList();
        that.loading = true;
        that.axios
            .post("WXCW81/GetLastTemperature", {
                serialNumber: that.serialNumber,
            })
            .then((response) => {
                that.loading = false;
                if (response.data.data) {
                    that.obj_WXCW81_Result = response.data.data;
                    if (that.obj_WXCW81_Result.gps) {
                        that.load_ZuoBiao();
                    }
                }
            });
        that.axios
            .post("Base_Device/GetModelBySerialNumber", {
                serialNumber: that.serialNumber,
            })
            .then((response) => {
                that.obj_Device = response.data.data;
            });
        that.axios
            .post("WXCW/GetConfig1", { serialNumber: that.serialNumber })
            .then((response) => {
                that.obj_Config = response.data.data;
            });

    },
};
</script>

<style scoped>
.primaryFont {
    color: #67c23a;
    font-weight: bolder;
}
</style>