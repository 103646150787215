<template>
    <div>
        <el-divider></el-divider>
        <el-row :gutter="10">
            <el-col :span="14">
                <el-input placeholder="请输入序列号" v-model="searchObj.onlyText" @change="getList(1)"><el-button slot="append" icon="el-icon-search" @click="getList(1)"></el-button></el-input>
                <vxe-table ref="table1" :data="tableData" border @current-change="currentChange" :row-config="rowConfig" :loading="tableloading" style="margin-top:10px;">
                    <vxe-column field="serialNumber" title="序列号" width="120"></vxe-column>
                    <vxe-column field="shiYanShiJian" title="试验时间" width="150"></vxe-column>
                    <vxe-column field="shiYanBianHao" title="试验编号" width="80" align="right"></vxe-column>
                    <vxe-column field="distanceX" title="间距X(cm)" width="90" align="right"></vxe-column>
                    <vxe-column field="distanceY" title="间距Y(cm)" width="90" align="right"></vxe-column>
                    <vxe-column field="maxValue" title="最大值(KΩ*cm)" align="right"></vxe-column>
                    <vxe-column field="avgValue" title="平均值(KΩ*cm)" align="right"></vxe-column>
                    <vxe-column field="count" title="数量" align="right" width="50"></vxe-column>
                    <vxe-column field="caozuo" title="操作" width="50">
                        <template #default="{ row }">
                            <vxe-button status="error" size="mini" icon="vxe-icon-delete" circle @click="del(row.id)"></vxe-button>
                        </template>
                    </vxe-column>
                </vxe-table>
                <vxe-pager align="left" @page-change="getList" :page-size.sync="searchObj.pageSize" :current-page.sync="searchObj.currentPage" :total="searchObj.total" :layouts="['PrevPage', 'Jump', 'PageCount', 'NextPage', 'Total']">
                </vxe-pager>
            </el-col>
            <el-col :span="10">
                <div style="height: 50px;display: flex;justify-content: end;align-items: center;">
                    <vxe-checkbox v-model="showValue" @change="showValueChange">显示值</vxe-checkbox>
                </div>
                <van-grid :column-num="7">
                    <van-grid-item>
                        <div :class="'item' + (dzl001_result.item11 == null ? 'kong' : '') + (dzl001_result.item11 >= 0 && dzl001_result.item11 < 10 ? '1' : '') + (dzl001_result.item11 >= 10 && dzl001_result.item11 < 50 ? '2' : '') + (dzl001_result.item11 >= 50 && dzl001_result.item11 < 75 ? '3' : '') + (dzl001_result.item11 >= 75 && dzl001_result.item11 < 100 ? '4' : '') + (dzl001_result.item11 >= 100 && dzl001_result.item11 < 150 ? '5' : '') + (dzl001_result.item11 >= 150 ? '6' : '')"
                            data-item-tag="11">{{ showValue ? dzl001_result.item11 : "" }}</div>
                    </van-grid-item>
                    <van-grid-item>
                        <div :class="'item' + (dzl001_result.item12 == null ? 'kong' : '') + (dzl001_result.item12 >= 0 && dzl001_result.item12 < 10 ? '1' : '') + (dzl001_result.item12 >= 10 && dzl001_result.item12 < 50 ? '2' : '') + (dzl001_result.item12 >= 50 && dzl001_result.item12 < 75 ? '3' : '') + (dzl001_result.item12 >= 75 && dzl001_result.item12 < 100 ? '4' : '') + (dzl001_result.item12 >= 100 && dzl001_result.item12 < 150 ? '5' : '') + (dzl001_result.item12 >= 150 ? '6' : '')"
                            data-item-tag="12">{{ showValue ? dzl001_result.item12 : "" }}</div>
                    </van-grid-item>
                    <van-grid-item>
                        <div :class="'item' + (dzl001_result.item13 == null ? 'kong' : '') + (dzl001_result.item13 >= 0 && dzl001_result.item13 < 10 ? '1' : '') + (dzl001_result.item13 >= 10 && dzl001_result.item13 < 50 ? '2' : '') + (dzl001_result.item13 >= 50 && dzl001_result.item13 < 75 ? '3' : '') + (dzl001_result.item13 >= 75 && dzl001_result.item13 < 100 ? '4' : '') + (dzl001_result.item13 >= 100 && dzl001_result.item13 < 150 ? '5' : '') + (dzl001_result.item13 >= 150 ? '6' : '')"
                            data-item-tag="13">{{ showValue ? dzl001_result.item13 : "" }}</div>
                    </van-grid-item>
                    <van-grid-item>
                        <div :class="'item' + (dzl001_result.item14 == null ? 'kong' : '') + (dzl001_result.item14 >= 0 && dzl001_result.item14 < 10 ? '1' : '') + (dzl001_result.item14 >= 10 && dzl001_result.item14 < 50 ? '2' : '') + (dzl001_result.item14 >= 50 && dzl001_result.item14 < 75 ? '3' : '') + (dzl001_result.item14 >= 75 && dzl001_result.item14 < 100 ? '4' : '') + (dzl001_result.item14 >= 100 && dzl001_result.item14 < 150 ? '5' : '') + (dzl001_result.item14 >= 150 ? '6' : '')"
                            data-item-tag="14">{{ showValue ? dzl001_result.item14 : "" }}</div>
                    </van-grid-item>
                    <van-grid-item>
                        <div :class="'item' + (dzl001_result.item15 == null ? 'kong' : '') + (dzl001_result.item15 >= 0 && dzl001_result.item15 < 10 ? '1' : '') + (dzl001_result.item15 >= 10 && dzl001_result.item15 < 50 ? '2' : '') + (dzl001_result.item15 >= 50 && dzl001_result.item15 < 75 ? '3' : '') + (dzl001_result.item15 >= 75 && dzl001_result.item15 < 100 ? '4' : '') + (dzl001_result.item15 >= 100 && dzl001_result.item15 < 150 ? '5' : '') + (dzl001_result.item15 >= 150 ? '6' : '')"
                            data-item-tag="15">{{ showValue ? dzl001_result.item15 : "" }}</div>
                    </van-grid-item>
                    <van-grid-item>
                        <div :class="'item' + (dzl001_result.item16 == null ? 'kong' : '') + (dzl001_result.item16 >= 0 && dzl001_result.item16 < 10 ? '1' : '') + (dzl001_result.item16 >= 10 && dzl001_result.item16 < 50 ? '2' : '') + (dzl001_result.item16 >= 50 && dzl001_result.item16 < 75 ? '3' : '') + (dzl001_result.item16 >= 75 && dzl001_result.item16 < 100 ? '4' : '') + (dzl001_result.item16 >= 100 && dzl001_result.item16 < 150 ? '5' : '') + (dzl001_result.item16 >= 150 ? '6' : '')"
                            data-item-tag="16">{{ showValue ? dzl001_result.item16 : "" }}</div>
                    </van-grid-item>
                    <van-grid-item>
                        <div :class="'item' + (dzl001_result.item17 == null ? 'kong' : '') + (dzl001_result.item17 >= 0 && dzl001_result.item17 < 10 ? '1' : '') + (dzl001_result.item17 >= 10 && dzl001_result.item17 < 50 ? '2' : '') + (dzl001_result.item17 >= 50 && dzl001_result.item17 < 75 ? '3' : '') + (dzl001_result.item17 >= 75 && dzl001_result.item17 < 100 ? '4' : '') + (dzl001_result.item17 >= 100 && dzl001_result.item17 < 150 ? '5' : '') + (dzl001_result.item17 >= 150 ? '6' : '')"
                            data-item-tag="17">{{ showValue ? dzl001_result.item17 : "" }}</div>
                    </van-grid-item>
                    <van-grid-item>
                        <div :class="'item' + (dzl001_result.item21 == null ? 'kong' : '') + (dzl001_result.item21 >= 0 && dzl001_result.item21 < 10 ? '1' : '') + (dzl001_result.item21 >= 10 && dzl001_result.item21 < 50 ? '2' : '') + (dzl001_result.item21 >= 50 && dzl001_result.item21 < 75 ? '3' : '') + (dzl001_result.item21 >= 75 && dzl001_result.item21 < 100 ? '4' : '') + (dzl001_result.item21 >= 100 && dzl001_result.item21 < 150 ? '5' : '') + (dzl001_result.item21 >= 150 ? '6' : '')"
                            data-item-tag="21">{{ showValue ? dzl001_result.item21 : "" }}</div>
                    </van-grid-item>
                    <van-grid-item>
                        <div :class="'item' + (dzl001_result.item22 == null ? 'kong' : '') + (dzl001_result.item22 >= 0 && dzl001_result.item22 < 10 ? '1' : '') + (dzl001_result.item22 >= 10 && dzl001_result.item22 < 50 ? '2' : '') + (dzl001_result.item22 >= 50 && dzl001_result.item22 < 75 ? '3' : '') + (dzl001_result.item22 >= 75 && dzl001_result.item22 < 100 ? '4' : '') + (dzl001_result.item22 >= 100 && dzl001_result.item22 < 150 ? '5' : '') + (dzl001_result.item22 >= 150 ? '6' : '')"
                            data-item-tag="22">{{ showValue ? dzl001_result.item22 : "" }}</div>
                    </van-grid-item>
                    <van-grid-item>
                        <div :class="'item' + (dzl001_result.item23 == null ? 'kong' : '') + (dzl001_result.item23 >= 0 && dzl001_result.item23 < 10 ? '1' : '') + (dzl001_result.item23 >= 10 && dzl001_result.item23 < 50 ? '2' : '') + (dzl001_result.item23 >= 50 && dzl001_result.item23 < 75 ? '3' : '') + (dzl001_result.item23 >= 75 && dzl001_result.item23 < 100 ? '4' : '') + (dzl001_result.item23 >= 100 && dzl001_result.item23 < 150 ? '5' : '') + (dzl001_result.item23 >= 150 ? '6' : '')"
                            data-item-tag="23">{{ showValue ? dzl001_result.item23 : "" }}</div>
                    </van-grid-item>
                    <van-grid-item>
                        <div :class="'item' + (dzl001_result.item24 == null ? 'kong' : '') + (dzl001_result.item24 >= 0 && dzl001_result.item24 < 10 ? '1' : '') + (dzl001_result.item24 >= 10 && dzl001_result.item24 < 50 ? '2' : '') + (dzl001_result.item24 >= 50 && dzl001_result.item24 < 75 ? '3' : '') + (dzl001_result.item24 >= 75 && dzl001_result.item24 < 100 ? '4' : '') + (dzl001_result.item24 >= 100 && dzl001_result.item24 < 150 ? '5' : '') + (dzl001_result.item24 >= 150 ? '6' : '')"
                            data-item-tag="24">{{ showValue ? dzl001_result.item24 : "" }}</div>
                    </van-grid-item>
                    <van-grid-item>
                        <div :class="'item' + (dzl001_result.item25 == null ? 'kong' : '') + (dzl001_result.item25 >= 0 && dzl001_result.item25 < 10 ? '1' : '') + (dzl001_result.item25 >= 10 && dzl001_result.item25 < 50 ? '2' : '') + (dzl001_result.item25 >= 50 && dzl001_result.item25 < 75 ? '3' : '') + (dzl001_result.item25 >= 75 && dzl001_result.item25 < 100 ? '4' : '') + (dzl001_result.item25 >= 100 && dzl001_result.item25 < 150 ? '5' : '') + (dzl001_result.item25 >= 150 ? '6' : '')"
                            data-item-tag="25">{{ showValue ? dzl001_result.item25 : "" }}</div>
                    </van-grid-item>
                    <van-grid-item>
                        <div :class="'item' + (dzl001_result.item26 == null ? 'kong' : '') + (dzl001_result.item26 >= 0 && dzl001_result.item26 < 10 ? '1' : '') + (dzl001_result.item26 >= 10 && dzl001_result.item26 < 50 ? '2' : '') + (dzl001_result.item26 >= 50 && dzl001_result.item26 < 75 ? '3' : '') + (dzl001_result.item26 >= 75 && dzl001_result.item26 < 100 ? '4' : '') + (dzl001_result.item26 >= 100 && dzl001_result.item26 < 150 ? '5' : '') + (dzl001_result.item26 >= 150 ? '6' : '')"
                            data-item-tag="26">{{ showValue ? dzl001_result.item26 : "" }}</div>
                    </van-grid-item>
                    <van-grid-item>
                        <div :class="'item' + (dzl001_result.item27 == null ? 'kong' : '') + (dzl001_result.item27 >= 0 && dzl001_result.item27 < 10 ? '1' : '') + (dzl001_result.item27 >= 10 && dzl001_result.item27 < 50 ? '2' : '') + (dzl001_result.item27 >= 50 && dzl001_result.item27 < 75 ? '3' : '') + (dzl001_result.item27 >= 75 && dzl001_result.item27 < 100 ? '4' : '') + (dzl001_result.item27 >= 100 && dzl001_result.item27 < 150 ? '5' : '') + (dzl001_result.item27 >= 150 ? '6' : '')"
                            data-item-tag="27">{{ showValue ? dzl001_result.item27 : "" }}</div>
                    </van-grid-item>
                    <van-grid-item>
                        <div :class="'item' + (dzl001_result.item31 == null ? 'kong' : '') + (dzl001_result.item31 >= 0 && dzl001_result.item31 < 10 ? '1' : '') + (dzl001_result.item31 >= 10 && dzl001_result.item31 < 50 ? '2' : '') + (dzl001_result.item31 >= 50 && dzl001_result.item31 < 75 ? '3' : '') + (dzl001_result.item31 >= 75 && dzl001_result.item31 < 100 ? '4' : '') + (dzl001_result.item31 >= 100 && dzl001_result.item31 < 150 ? '5' : '') + (dzl001_result.item31 >= 150 ? '6' : '')"
                            data-item-tag="31">{{ showValue ? dzl001_result.item31 : "" }}</div>
                    </van-grid-item>
                    <van-grid-item>
                        <div :class="'item' + (dzl001_result.item32 == null ? 'kong' : '') + (dzl001_result.item32 >= 0 && dzl001_result.item32 < 10 ? '1' : '') + (dzl001_result.item32 >= 10 && dzl001_result.item32 < 50 ? '2' : '') + (dzl001_result.item32 >= 50 && dzl001_result.item32 < 75 ? '3' : '') + (dzl001_result.item32 >= 75 && dzl001_result.item32 < 100 ? '4' : '') + (dzl001_result.item32 >= 100 && dzl001_result.item32 < 150 ? '5' : '') + (dzl001_result.item32 >= 150 ? '6' : '')"
                            data-item-tag="32">{{ showValue ? dzl001_result.item32 : "" }}</div>
                    </van-grid-item>
                    <van-grid-item>
                        <div :class="'item' + (dzl001_result.item33 == null ? 'kong' : '') + (dzl001_result.item33 >= 0 && dzl001_result.item33 < 10 ? '1' : '') + (dzl001_result.item33 >= 10 && dzl001_result.item33 < 50 ? '2' : '') + (dzl001_result.item33 >= 50 && dzl001_result.item33 < 75 ? '3' : '') + (dzl001_result.item33 >= 75 && dzl001_result.item33 < 100 ? '4' : '') + (dzl001_result.item33 >= 100 && dzl001_result.item33 < 150 ? '5' : '') + (dzl001_result.item33 >= 150 ? '6' : '')"
                            data-item-tag="33">{{ showValue ? dzl001_result.item33 : "" }}</div>
                    </van-grid-item>
                    <van-grid-item>
                        <div :class="'item' + (dzl001_result.item34 == null ? 'kong' : '') + (dzl001_result.item34 >= 0 && dzl001_result.item34 < 10 ? '1' : '') + (dzl001_result.item34 >= 10 && dzl001_result.item34 < 50 ? '2' : '') + (dzl001_result.item34 >= 50 && dzl001_result.item34 < 75 ? '3' : '') + (dzl001_result.item34 >= 75 && dzl001_result.item34 < 100 ? '4' : '') + (dzl001_result.item34 >= 100 && dzl001_result.item34 < 150 ? '5' : '') + (dzl001_result.item34 >= 150 ? '6' : '')"
                            data-item-tag="34">{{ showValue ? dzl001_result.item34 : "" }}</div>
                    </van-grid-item>
                    <van-grid-item>
                        <div :class="'item' + (dzl001_result.item35 == null ? 'kong' : '') + (dzl001_result.item35 >= 0 && dzl001_result.item35 < 10 ? '1' : '') + (dzl001_result.item35 >= 10 && dzl001_result.item35 < 50 ? '2' : '') + (dzl001_result.item35 >= 50 && dzl001_result.item35 < 75 ? '3' : '') + (dzl001_result.item35 >= 75 && dzl001_result.item35 < 100 ? '4' : '') + (dzl001_result.item35 >= 100 && dzl001_result.item35 < 150 ? '5' : '') + (dzl001_result.item35 >= 150 ? '6' : '')"
                            data-item-tag="35">{{ showValue ? dzl001_result.item35 : "" }}</div>
                    </van-grid-item>
                    <van-grid-item>
                        <div :class="'item' + (dzl001_result.item36 == null ? 'kong' : '') + (dzl001_result.item36 >= 0 && dzl001_result.item36 < 10 ? '1' : '') + (dzl001_result.item36 >= 10 && dzl001_result.item36 < 50 ? '2' : '') + (dzl001_result.item36 >= 50 && dzl001_result.item36 < 75 ? '3' : '') + (dzl001_result.item36 >= 75 && dzl001_result.item36 < 100 ? '4' : '') + (dzl001_result.item36 >= 100 && dzl001_result.item36 < 150 ? '5' : '') + (dzl001_result.item36 >= 150 ? '6' : '')"
                            data-item-tag="36">{{ showValue ? dzl001_result.item36 : "" }}</div>
                    </van-grid-item>
                    <van-grid-item>
                        <div :class="'item' + (dzl001_result.item37 == null ? 'kong' : '') + (dzl001_result.item37 >= 0 && dzl001_result.item37 < 10 ? '1' : '') + (dzl001_result.item37 >= 10 && dzl001_result.item37 < 50 ? '2' : '') + (dzl001_result.item37 >= 50 && dzl001_result.item37 < 75 ? '3' : '') + (dzl001_result.item37 >= 75 && dzl001_result.item37 < 100 ? '4' : '') + (dzl001_result.item37 >= 100 && dzl001_result.item37 < 150 ? '5' : '') + (dzl001_result.item37 >= 150 ? '6' : '')"
                            data-item-tag="37">{{ showValue ? dzl001_result.item37 : "" }}</div>
                    </van-grid-item>
                    <van-grid-item>
                        <div :class="'item' + (dzl001_result.item41 == null ? 'kong' : '') + (dzl001_result.item41 >= 0 && dzl001_result.item41 < 10 ? '1' : '') + (dzl001_result.item41 >= 10 && dzl001_result.item41 < 50 ? '2' : '') + (dzl001_result.item41 >= 50 && dzl001_result.item41 < 75 ? '3' : '') + (dzl001_result.item41 >= 75 && dzl001_result.item41 < 100 ? '4' : '') + (dzl001_result.item41 >= 100 && dzl001_result.item41 < 150 ? '5' : '') + (dzl001_result.item41 >= 150 ? '6' : '')"
                            data-item-tag="41">{{ showValue ? dzl001_result.item41 : "" }}</div>
                    </van-grid-item>
                    <van-grid-item>
                        <div :class="'item' + (dzl001_result.item42 == null ? 'kong' : '') + (dzl001_result.item42 >= 0 && dzl001_result.item42 < 10 ? '1' : '') + (dzl001_result.item42 >= 10 && dzl001_result.item42 < 50 ? '2' : '') + (dzl001_result.item42 >= 50 && dzl001_result.item42 < 75 ? '3' : '') + (dzl001_result.item42 >= 75 && dzl001_result.item42 < 100 ? '4' : '') + (dzl001_result.item42 >= 100 && dzl001_result.item42 < 150 ? '5' : '') + (dzl001_result.item42 >= 150 ? '6' : '')"
                            data-item-tag="42">{{ showValue ? dzl001_result.item42 : "" }}</div>
                    </van-grid-item>
                    <van-grid-item>
                        <div :class="'item' + (dzl001_result.item43 == null ? 'kong' : '') + (dzl001_result.item43 >= 0 && dzl001_result.item43 < 10 ? '1' : '') + (dzl001_result.item43 >= 10 && dzl001_result.item43 < 50 ? '2' : '') + (dzl001_result.item43 >= 50 && dzl001_result.item43 < 75 ? '3' : '') + (dzl001_result.item43 >= 75 && dzl001_result.item43 < 100 ? '4' : '') + (dzl001_result.item43 >= 100 && dzl001_result.item43 < 150 ? '5' : '') + (dzl001_result.item43 >= 150 ? '6' : '')"
                            data-item-tag="43">{{ showValue ? dzl001_result.item43 : "" }}</div>
                    </van-grid-item>
                    <van-grid-item>
                        <div :class="'item' + (dzl001_result.item44 == null ? 'kong' : '') + (dzl001_result.item44 >= 0 && dzl001_result.item44 < 10 ? '1' : '') + (dzl001_result.item44 >= 10 && dzl001_result.item44 < 50 ? '2' : '') + (dzl001_result.item44 >= 50 && dzl001_result.item44 < 75 ? '3' : '') + (dzl001_result.item44 >= 75 && dzl001_result.item44 < 100 ? '4' : '') + (dzl001_result.item44 >= 100 && dzl001_result.item44 < 150 ? '5' : '') + (dzl001_result.item44 >= 150 ? '6' : '')"
                            data-item-tag="44">{{ showValue ? dzl001_result.item44 : "" }}</div>
                    </van-grid-item>
                    <van-grid-item>
                        <div :class="'item' + (dzl001_result.item45 == null ? 'kong' : '') + (dzl001_result.item45 >= 0 && dzl001_result.item45 < 10 ? '1' : '') + (dzl001_result.item45 >= 10 && dzl001_result.item45 < 50 ? '2' : '') + (dzl001_result.item45 >= 50 && dzl001_result.item45 < 75 ? '3' : '') + (dzl001_result.item45 >= 75 && dzl001_result.item45 < 100 ? '4' : '') + (dzl001_result.item45 >= 100 && dzl001_result.item45 < 150 ? '5' : '') + (dzl001_result.item45 >= 150 ? '6' : '')"
                            data-item-tag="45">{{ showValue ? dzl001_result.item45 : "" }}</div>
                    </van-grid-item>
                    <van-grid-item>
                        <div :class="'item' + (dzl001_result.item46 == null ? 'kong' : '') + (dzl001_result.item46 >= 0 && dzl001_result.item46 < 10 ? '1' : '') + (dzl001_result.item46 >= 10 && dzl001_result.item46 < 50 ? '2' : '') + (dzl001_result.item46 >= 50 && dzl001_result.item46 < 75 ? '3' : '') + (dzl001_result.item46 >= 75 && dzl001_result.item46 < 100 ? '4' : '') + (dzl001_result.item46 >= 100 && dzl001_result.item46 < 150 ? '5' : '') + (dzl001_result.item46 >= 150 ? '6' : '')"
                            data-item-tag="46">{{ showValue ? dzl001_result.item46 : "" }}</div>
                    </van-grid-item>
                    <van-grid-item>
                        <div :class="'item' + (dzl001_result.item47 == null ? 'kong' : '') + (dzl001_result.item47 >= 0 && dzl001_result.item47 < 10 ? '1' : '') + (dzl001_result.item47 >= 10 && dzl001_result.item47 < 50 ? '2' : '') + (dzl001_result.item47 >= 50 && dzl001_result.item47 < 75 ? '3' : '') + (dzl001_result.item47 >= 75 && dzl001_result.item47 < 100 ? '4' : '') + (dzl001_result.item47 >= 100 && dzl001_result.item47 < 150 ? '5' : '') + (dzl001_result.item47 >= 150 ? '6' : '')"
                            data-item-tag="47">{{ showValue ? dzl001_result.item47 : "" }}</div>
                    </van-grid-item>
                    <van-grid-item>
                        <div :class="'item' + (dzl001_result.item51 == null ? 'kong' : '') + (dzl001_result.item51 >= 0 && dzl001_result.item51 < 10 ? '1' : '') + (dzl001_result.item51 >= 10 && dzl001_result.item51 < 50 ? '2' : '') + (dzl001_result.item51 >= 50 && dzl001_result.item51 < 75 ? '3' : '') + (dzl001_result.item51 >= 75 && dzl001_result.item51 < 100 ? '4' : '') + (dzl001_result.item51 >= 100 && dzl001_result.item51 < 150 ? '5' : '') + (dzl001_result.item51 >= 150 ? '6' : '')"
                            data-item-tag="51">{{ showValue ? dzl001_result.item51 : "" }}</div>
                    </van-grid-item>
                    <van-grid-item>
                        <div :class="'item' + (dzl001_result.item52 == null ? 'kong' : '') + (dzl001_result.item52 >= 0 && dzl001_result.item52 < 10 ? '1' : '') + (dzl001_result.item52 >= 10 && dzl001_result.item52 < 50 ? '2' : '') + (dzl001_result.item52 >= 50 && dzl001_result.item52 < 75 ? '3' : '') + (dzl001_result.item52 >= 75 && dzl001_result.item52 < 100 ? '4' : '') + (dzl001_result.item52 >= 100 && dzl001_result.item52 < 150 ? '5' : '') + (dzl001_result.item52 >= 150 ? '6' : '')"
                            data-item-tag="52">{{ showValue ? dzl001_result.item52 : "" }}</div>
                    </van-grid-item>
                    <van-grid-item>
                        <div :class="'item' + (dzl001_result.item53 == null ? 'kong' : '') + (dzl001_result.item53 >= 0 && dzl001_result.item53 < 10 ? '1' : '') + (dzl001_result.item53 >= 10 && dzl001_result.item53 < 50 ? '2' : '') + (dzl001_result.item53 >= 50 && dzl001_result.item53 < 75 ? '3' : '') + (dzl001_result.item53 >= 75 && dzl001_result.item53 < 100 ? '4' : '') + (dzl001_result.item53 >= 100 && dzl001_result.item53 < 150 ? '5' : '') + (dzl001_result.item53 >= 150 ? '6' : '')"
                            data-item-tag="53">{{ showValue ? dzl001_result.item53 : "" }}</div>
                    </van-grid-item>
                    <van-grid-item>
                        <div :class="'item' + (dzl001_result.item54 == null ? 'kong' : '') + (dzl001_result.item54 >= 0 && dzl001_result.item54 < 10 ? '1' : '') + (dzl001_result.item54 >= 10 && dzl001_result.item54 < 50 ? '2' : '') + (dzl001_result.item54 >= 50 && dzl001_result.item54 < 75 ? '3' : '') + (dzl001_result.item54 >= 75 && dzl001_result.item54 < 100 ? '4' : '') + (dzl001_result.item54 >= 100 && dzl001_result.item54 < 150 ? '5' : '') + (dzl001_result.item54 >= 150 ? '6' : '')"
                            data-item-tag="54">{{ showValue ? dzl001_result.item54 : "" }}</div>
                    </van-grid-item>
                    <van-grid-item>
                        <div :class="'item' + (dzl001_result.item55 == null ? 'kong' : '') + (dzl001_result.item55 >= 0 && dzl001_result.item55 < 10 ? '1' : '') + (dzl001_result.item55 >= 10 && dzl001_result.item55 < 50 ? '2' : '') + (dzl001_result.item55 >= 50 && dzl001_result.item55 < 75 ? '3' : '') + (dzl001_result.item55 >= 75 && dzl001_result.item55 < 100 ? '4' : '') + (dzl001_result.item55 >= 100 && dzl001_result.item55 < 150 ? '5' : '') + (dzl001_result.item55 >= 150 ? '6' : '')"
                            data-item-tag="55">{{ showValue ? dzl001_result.item55 : "" }}</div>
                    </van-grid-item>
                    <van-grid-item>
                        <div :class="'item' + (dzl001_result.item56 == null ? 'kong' : '') + (dzl001_result.item56 >= 0 && dzl001_result.item56 < 10 ? '1' : '') + (dzl001_result.item56 >= 10 && dzl001_result.item56 < 50 ? '2' : '') + (dzl001_result.item56 >= 50 && dzl001_result.item56 < 75 ? '3' : '') + (dzl001_result.item56 >= 75 && dzl001_result.item56 < 100 ? '4' : '') + (dzl001_result.item56 >= 100 && dzl001_result.item56 < 150 ? '5' : '') + (dzl001_result.item56 >= 150 ? '6' : '')"
                            data-item-tag="56">{{ showValue ? dzl001_result.item56 : "" }}</div>
                    </van-grid-item>
                    <van-grid-item>
                        <div :class="'item' + (dzl001_result.item57 == null ? 'kong' : '') + (dzl001_result.item57 >= 0 && dzl001_result.item57 < 10 ? '1' : '') + (dzl001_result.item57 >= 10 && dzl001_result.item57 < 50 ? '2' : '') + (dzl001_result.item57 >= 50 && dzl001_result.item57 < 75 ? '3' : '') + (dzl001_result.item57 >= 75 && dzl001_result.item57 < 100 ? '4' : '') + (dzl001_result.item57 >= 100 && dzl001_result.item57 < 150 ? '5' : '') + (dzl001_result.item57 >= 150 ? '6' : '')"
                            data-item-tag="57">{{ showValue ? dzl001_result.item57 : "" }}</div>
                    </van-grid-item>
                    <van-grid-item>
                        <div :class="'item' + (dzl001_result.item61 == null ? 'kong' : '') + (dzl001_result.item61 >= 0 && dzl001_result.item61 < 10 ? '1' : '') + (dzl001_result.item61 >= 10 && dzl001_result.item61 < 50 ? '2' : '') + (dzl001_result.item61 >= 50 && dzl001_result.item61 < 75 ? '3' : '') + (dzl001_result.item61 >= 75 && dzl001_result.item61 < 100 ? '4' : '') + (dzl001_result.item61 >= 100 && dzl001_result.item61 < 150 ? '5' : '') + (dzl001_result.item61 >= 150 ? '6' : '')"
                            data-item-tag="61">{{ showValue ? dzl001_result.item61 : "" }}</div>
                    </van-grid-item>
                    <van-grid-item>
                        <div :class="'item' + (dzl001_result.item62 == null ? 'kong' : '') + (dzl001_result.item62 >= 0 && dzl001_result.item62 < 10 ? '1' : '') + (dzl001_result.item62 >= 10 && dzl001_result.item62 < 50 ? '2' : '') + (dzl001_result.item62 >= 50 && dzl001_result.item62 < 75 ? '3' : '') + (dzl001_result.item62 >= 75 && dzl001_result.item62 < 100 ? '4' : '') + (dzl001_result.item62 >= 100 && dzl001_result.item62 < 150 ? '5' : '') + (dzl001_result.item62 >= 150 ? '6' : '')"
                            data-item-tag="62">{{ showValue ? dzl001_result.item62 : "" }}</div>
                    </van-grid-item>
                    <van-grid-item>
                        <div :class="'item' + (dzl001_result.item63 == null ? 'kong' : '') + (dzl001_result.item63 >= 0 && dzl001_result.item63 < 10 ? '1' : '') + (dzl001_result.item63 >= 10 && dzl001_result.item63 < 50 ? '2' : '') + (dzl001_result.item63 >= 50 && dzl001_result.item63 < 75 ? '3' : '') + (dzl001_result.item63 >= 75 && dzl001_result.item63 < 100 ? '4' : '') + (dzl001_result.item63 >= 100 && dzl001_result.item63 < 150 ? '5' : '') + (dzl001_result.item63 >= 150 ? '6' : '')"
                            data-item-tag="63">{{ showValue ? dzl001_result.item63 : "" }}</div>
                    </van-grid-item>
                    <van-grid-item>
                        <div :class="'item' + (dzl001_result.item64 == null ? 'kong' : '') + (dzl001_result.item64 >= 0 && dzl001_result.item64 < 10 ? '1' : '') + (dzl001_result.item64 >= 10 && dzl001_result.item64 < 50 ? '2' : '') + (dzl001_result.item64 >= 50 && dzl001_result.item64 < 75 ? '3' : '') + (dzl001_result.item64 >= 75 && dzl001_result.item64 < 100 ? '4' : '') + (dzl001_result.item64 >= 100 && dzl001_result.item64 < 150 ? '5' : '') + (dzl001_result.item64 >= 150 ? '6' : '')"
                            data-item-tag="64">{{ showValue ? dzl001_result.item64 : "" }}</div>
                    </van-grid-item>
                    <van-grid-item>
                        <div :class="'item' + (dzl001_result.item65 == null ? 'kong' : '') + (dzl001_result.item65 >= 0 && dzl001_result.item65 < 10 ? '1' : '') + (dzl001_result.item65 >= 10 && dzl001_result.item65 < 50 ? '2' : '') + (dzl001_result.item65 >= 50 && dzl001_result.item65 < 75 ? '3' : '') + (dzl001_result.item65 >= 75 && dzl001_result.item65 < 100 ? '4' : '') + (dzl001_result.item65 >= 100 && dzl001_result.item65 < 150 ? '5' : '') + (dzl001_result.item65 >= 150 ? '6' : '')"
                            data-item-tag="65">{{ showValue ? dzl001_result.item65 : "" }}</div>
                    </van-grid-item>
                    <van-grid-item>
                        <div :class="'item' + (dzl001_result.item66 == null ? 'kong' : '') + (dzl001_result.item66 >= 0 && dzl001_result.item66 < 10 ? '1' : '') + (dzl001_result.item66 >= 10 && dzl001_result.item66 < 50 ? '2' : '') + (dzl001_result.item66 >= 50 && dzl001_result.item66 < 75 ? '3' : '') + (dzl001_result.item66 >= 75 && dzl001_result.item66 < 100 ? '4' : '') + (dzl001_result.item66 >= 100 && dzl001_result.item66 < 150 ? '5' : '') + (dzl001_result.item66 >= 150 ? '6' : '')"
                            data-item-tag="66">{{ showValue ? dzl001_result.item66 : "" }}</div>
                    </van-grid-item>
                    <van-grid-item>
                        <div :class="'item' + (dzl001_result.item67 == null ? 'kong' : '') + (dzl001_result.item67 >= 0 && dzl001_result.item67 < 10 ? '1' : '') + (dzl001_result.item67 >= 10 && dzl001_result.item67 < 50 ? '2' : '') + (dzl001_result.item67 >= 50 && dzl001_result.item67 < 75 ? '3' : '') + (dzl001_result.item67 >= 75 && dzl001_result.item67 < 100 ? '4' : '') + (dzl001_result.item67 >= 100 && dzl001_result.item67 < 150 ? '5' : '') + (dzl001_result.item67 >= 150 ? '6' : '')"
                            data-item-tag="67">{{ showValue ? dzl001_result.item67 : "" }}</div>
                    </van-grid-item>
                    <van-grid-item>
                        <div :class="'item' + (dzl001_result.item71 == null ? 'kong' : '') + (dzl001_result.item71 >= 0 && dzl001_result.item71 < 10 ? '1' : '') + (dzl001_result.item71 >= 10 && dzl001_result.item71 < 50 ? '2' : '') + (dzl001_result.item71 >= 50 && dzl001_result.item71 < 75 ? '3' : '') + (dzl001_result.item71 >= 75 && dzl001_result.item71 < 100 ? '4' : '') + (dzl001_result.item71 >= 100 && dzl001_result.item71 < 150 ? '5' : '') + (dzl001_result.item71 >= 150 ? '6' : '')"
                            data-item-tag="71">{{ showValue ? dzl001_result.item71 : "" }}</div>
                    </van-grid-item>
                    <van-grid-item>
                        <div :class="'item' + (dzl001_result.item72 == null ? 'kong' : '') + (dzl001_result.item72 >= 0 && dzl001_result.item72 < 10 ? '1' : '') + (dzl001_result.item72 >= 10 && dzl001_result.item72 < 50 ? '2' : '') + (dzl001_result.item72 >= 50 && dzl001_result.item72 < 75 ? '3' : '') + (dzl001_result.item72 >= 75 && dzl001_result.item72 < 100 ? '4' : '') + (dzl001_result.item72 >= 100 && dzl001_result.item72 < 150 ? '5' : '') + (dzl001_result.item72 >= 150 ? '6' : '')"
                            data-item-tag="72">{{ showValue ? dzl001_result.item72 : "" }}</div>
                    </van-grid-item>
                    <van-grid-item>
                        <div :class="'item' + (dzl001_result.item73 == null ? 'kong' : '') + (dzl001_result.item73 >= 0 && dzl001_result.item73 < 10 ? '1' : '') + (dzl001_result.item73 >= 10 && dzl001_result.item73 < 50 ? '2' : '') + (dzl001_result.item73 >= 50 && dzl001_result.item73 < 75 ? '3' : '') + (dzl001_result.item73 >= 75 && dzl001_result.item73 < 100 ? '4' : '') + (dzl001_result.item73 >= 100 && dzl001_result.item73 < 150 ? '5' : '') + (dzl001_result.item73 >= 150 ? '6' : '')"
                            data-item-tag="73">{{ showValue ? dzl001_result.item73 : "" }}</div>
                    </van-grid-item>
                    <van-grid-item>
                        <div :class="'item' + (dzl001_result.item74 == null ? 'kong' : '') + (dzl001_result.item74 >= 0 && dzl001_result.item74 < 10 ? '1' : '') + (dzl001_result.item74 >= 10 && dzl001_result.item74 < 50 ? '2' : '') + (dzl001_result.item74 >= 50 && dzl001_result.item74 < 75 ? '3' : '') + (dzl001_result.item74 >= 75 && dzl001_result.item74 < 100 ? '4' : '') + (dzl001_result.item74 >= 100 && dzl001_result.item74 < 150 ? '5' : '') + (dzl001_result.item74 >= 150 ? '6' : '')"
                            data-item-tag="74">{{ showValue ? dzl001_result.item74 : "" }}</div>
                    </van-grid-item>
                    <van-grid-item>
                        <div :class="'item' + (dzl001_result.item75 == null ? 'kong' : '') + (dzl001_result.item75 >= 0 && dzl001_result.item75 < 10 ? '1' : '') + (dzl001_result.item75 >= 10 && dzl001_result.item75 < 50 ? '2' : '') + (dzl001_result.item75 >= 50 && dzl001_result.item75 < 75 ? '3' : '') + (dzl001_result.item75 >= 75 && dzl001_result.item75 < 100 ? '4' : '') + (dzl001_result.item75 >= 100 && dzl001_result.item75 < 150 ? '5' : '') + (dzl001_result.item75 >= 150 ? '6' : '')"
                            data-item-tag="75">{{ showValue ? dzl001_result.item75 : "" }}</div>
                    </van-grid-item>
                    <van-grid-item>
                        <div :class="'item' + (dzl001_result.item76 == null ? 'kong' : '') + (dzl001_result.item76 >= 0 && dzl001_result.item76 < 10 ? '1' : '') + (dzl001_result.item76 >= 10 && dzl001_result.item76 < 50 ? '2' : '') + (dzl001_result.item76 >= 50 && dzl001_result.item76 < 75 ? '3' : '') + (dzl001_result.item76 >= 75 && dzl001_result.item76 < 100 ? '4' : '') + (dzl001_result.item76 >= 100 && dzl001_result.item76 < 150 ? '5' : '') + (dzl001_result.item76 >= 150 ? '6' : '')"
                            data-item-tag="76">{{ showValue ? dzl001_result.item76 : "" }}</div>
                    </van-grid-item>
                    <van-grid-item>
                        <div :class="'item' + (dzl001_result.item77 == null ? 'kong' : '') + (dzl001_result.item77 >= 0 && dzl001_result.item77 < 10 ? '1' : '') + (dzl001_result.item77 >= 10 && dzl001_result.item77 < 50 ? '2' : '') + (dzl001_result.item77 >= 50 && dzl001_result.item77 < 75 ? '3' : '') + (dzl001_result.item77 >= 75 && dzl001_result.item77 < 100 ? '4' : '') + (dzl001_result.item77 >= 100 && dzl001_result.item77 < 150 ? '5' : '') + (dzl001_result.item77 >= 150 ? '6' : '')"
                            data-item-tag="77">{{ showValue ? dzl001_result.item77 : "" }}</div>
                    </van-grid-item>
                </van-grid>
                <div style="display: flex;justify-content: space-around;margin: 20px 0px;">
                    <div style="display: flex;align-items: center;font-size: 12px;">
                        <div>0-10</div>
                        <div class="item-demo1"></div>
                    </div>
                    <div style="display: flex;align-items: center;font-size: 12px;">
                        <div>10-50</div>
                        <div class="item-demo2"></div>
                    </div>
                    <div style="display: flex;align-items: center;font-size: 12px;">
                        <div>50-75</div>
                        <div class="item-demo3"></div>
                    </div>
                    <div style="display: flex;align-items: center;font-size: 12px;">
                        <div>75-100</div>
                        <div class="item-demo4"></div>
                    </div>
                    <div style="display: flex;align-items: center;font-size: 12px;">
                        <div>100-150</div>
                        <div class="item-demo5"></div>
                    </div>
                    <div style="display: flex;align-items: center;font-size: 12px;">
                        <div>>150</div>
                        <div class="item-demo6"></div>
                    </div>
                </div>
            </el-col>
        </el-row>
    </div>
</template>

<script>

import Vue from 'vue';
import { VxeUI } from 'vxe-pc-ui';
import { Grid, GridItem } from 'vant';

Vue.use(Grid);
Vue.use(GridItem);
export default {
    data() {
        let that = this;
        return {
            showValue: that.$cookies.get("dzl001_show_value") == "true",
            rowConfig: { isCurrent: true, isHover: true },
            tableloading: false,
            searchObj: {
                onlyText: "",
                currentPage: 1,
                pageSize: 10,
                total: 0,
            },
            tableData: [],
            dzl001_result: {},
            items: []
        };
    },
    methods: {
        showValueChange({ checked }) {
            let that = this;
            that.$cookies.set("dzl001_show_value", checked);
        },
        currentChange({ newValue }) {
            let that = this;
            that.dzl001_result = newValue;

            //null小于0，undefine小于0都为true
            for (let i = 0; i < that.dzl001_result.count; i++) {
                that.items.push(that.dzl001_result["item" + (parseInt(i / 7) + 1).toString() + (i % 7 + 1).toString()]);
            }

        },
        getList(v) {
            let that = this;
            that.tableloading = true;
            if (v == 1) {
                that.searchObj.currentPage = 1;
            }
            that.axios.post("DZL001/GetList1", that.searchObj).then((res) => {
                that.tableloading = false;
                that.searchObj.total = res.data.data.total;
                that.tableData = res.data.data.data;
                if (that.tableData.length > 0) {
                    that.$refs.table1.setCurrentRow(that.tableData[0]);
                    that.currentChange({ newValue: that.tableData[0] });
                }
            });
        },
        del(v) {
            let that = this;
            VxeUI.modal.confirm("确定要删除吗?", "提示").then((res) => {
                if (res == "confirm") {
                    that.axios.post("DZL001/DelModel", { onlyInt: v }).then(() => {
                        that.getList();
                    });
                }
            });
        }
    },
    mounted() {
        let that = this;
        that.getList();
    }
};
</script>

<style scoped>
.item1 {
    width: 35px;
    height: 35px;
    border-radius: 5px;
    background-color: #FF0000;
    border: 1px solid #FF0000;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 10px;
    font-weight: bolder;
    color: white;
}

.item2 {
    width: 35px;
    height: 35px;
    border-radius: 5px;
    background-color: #FFA500;
    border: 1px solid #FFA500;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 10px;
    font-weight: bolder;
    color: white;
}

.item3 {
    width: 35px;
    height: 35px;
    border-radius: 5px;
    background-color: #D2B48C;
    border: 1px solid #D2B48C;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 10px;
    font-weight: bolder;
    color: white;
}

.item4 {
    width: 35px;
    height: 35px;
    border-radius: 5px;
    background-color: #A0522D;
    border: 1px solid #A0522D;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 10px;
    font-weight: bolder;
    color: white;
}

.item5 {
    width: 35px;
    height: 35px;
    border-radius: 5px;
    background-color: #FFD700;
    border: 1px solid #FFD700;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 10px;
    font-weight: bolder;
    color: white;
}

.item6 {
    width: 35px;
    height: 35px;
    border-radius: 5px;
    background-color: #00FF00;
    border: 1px solid #00FF00;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 10px;
    font-weight: bolder;
    color: white;
}

.itemkong1 {
    width: 35px;
    height: 35px;
    border-radius: 5px;
    border: 1px solid #ccc;
}

.item-demo1 {
    width: 20px;
    height: 10px;
    background-color: #FF0000;
}

.item-demo2 {
    width: 20px;
    height: 10px;
    background-color: #FFA500;
}

.item-demo3 {
    width: 20px;
    height: 10px;
    background-color: #D2B48C;
}

.item-demo4 {
    width: 20px;
    height: 10px;
    background-color: #A0522D;
}

.item-demo5 {
    width: 20px;
    height: 10px;
    background-color: #FFD700;
}

.item-demo6 {
    width: 20px;
    height: 10px;
    background-color: #00FF00;
}
</style>