<template>
    <div>
        <el-input class="www200"></el-input>
        <el-button type="primary" @click="test">fdfsdsdf</el-button>
        <el-image :src="imageUrl" fit="fill">
            <div slot="error" class="image-slot">
                <i class="el-icon-picture-outline"></i>
            </div>
        </el-image>
    </div>
</template>

<script>
export default {
    data() {
        return { imageUrl: "" };
    },
    methods: {
        test() {
            let that = this;
            that.axios.post("Test/ReceivePZDData1", {}).then((respose) => {
                console.log(respose);
                that.imageUrl = "data:image/png;base64," + respose.data;
            });
        }
    }
};
</script>
<style scoped>
.www200 {
    width: 200px;
}
</style>