<template>
    <div>
        <el-radio-group v-model="selectDeviceType" @input="loadUpgradeFile">
            <el-radio-button label="LQY08"></el-radio-button>
            <el-radio-button label="WDD08"></el-radio-button>
            <el-radio-button label="RHD008"></el-radio-button>
            <el-radio-button label="ZRD008"></el-radio-button>
            <el-radio-button label="RSL80"></el-radio-button>
            <el-radio-button label="PZD801"></el-radio-button>
        </el-radio-group>
        <el-divider>版本信息</el-divider>
        <el-row v-for="(item, index) in versionList" :key="index" style="margin-bottom: 40px;">
            <el-col :span="4">
                <el-radio :label="item.version" v-model="currentVersion" @input="updateVersion" border></el-radio>
            </el-col>
            <el-col :span="20">
                <el-input label="备注" v-model="item.remark" @change="updateRemark(index)"><template #prepend>备注</template></el-input>
            </el-col>
        </el-row>
        <el-empty v-if="versionList == null || versionList.length == 0" description="无版本信息"></el-empty>
    </div>
</template>

<script>
export default {
    data() {
        return {
            selectDeviceType: "LQY08",
            currentVersion: "",
            versionList: {},
        };
    },
    methods: {
        loadUpgradeFile() {
            let that = this;
            that.axios.post("DeviceUpgradeFixedPath/LoadVersionList", { onlyText: that.selectDeviceType }).then((response) => {
                that.versionList = response.data.data;
                that.loadCurrentVersion();

            });
        },
        loadCurrentVersion() {
            let that = this;
            that.axios.post("DeviceUpgradeFixedPath/GetCurrentVersion", { onlyText: that.selectDeviceType }).then((response) => {
                that.currentVersion = response.data.data;

            });
        },
        updateVersion() {
            let that = this;
            const loading = this.$loading({
                lock: true,
                text: "切换中...",
                spinner: "el-icon-loading",
                background: "rgba(0, 0, 0, 0.7)",
            });
            that.axios.post("DeviceUpgradeFixedPath/UpdateVersion", { deviceType: that.selectDeviceType, version: that.currentVersion }).then((response) => {
                loading.close();
                if (response.data.code == 101) {
                    that.$message({ type: "success", message: "切换成功" });
                }
                else {
                    that.$message({ type: "error", message: response.data.msg });
                }

            });
        },
        updateRemark(i) {
            let that = this;
            that.axios
                .post("DeviceUpgradeFixedPath/UpdateRemark", {
                    deviceType: that.selectDeviceType,
                    version: that.versionList[i].version,
                    remark: that.versionList[i].remark,
                })
                .then(function (response) {
                    if (response.data.code == 101) {
                        that.$message.success("保存成功");
                    } else {
                        that.$message.error(response.data.msg);
                    }
                });
        },
    },

    mounted() {
        let that = this;
        that.loadUpgradeFile(that.selectDeviceType);
    },
};
</script>

<style scoped></style>
