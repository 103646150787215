<template>
    <dv-full-screen-container class="main">
        <Header></Header>
        <div class="main1">
            <dv-border-box-11 style="width:20%;" title="设备列表" ref="refDeviceList">
                <div style="padding:70px 50px;">
                    <swiper :options="swiperOption" ref="mySwiper" :style="'height:' + swiperHeight + 'px;color:white;'">
                        <swiper-slide v-for="(item, index) in deviceList" :key="index">
                            <dv-border-box-10>
                                <div style="display:flex; justify-content:center; align-items:center;height:100%;">
                                    <dv-decoration-11 style="width:90%;height:50%;">{{ item.remark ? item.remark :
                                        item.serialNumber }}</dv-decoration-11>
                                </div>
                            </dv-border-box-10>
                        </swiper-slide>
                        <swiper-slide></swiper-slide>
                        <swiper-slide></swiper-slide>
                    </swiper>
                </div>
            </dv-border-box-11>
            <dv-border-box-8 style="flex:1;">
                <div style="display:flex;flex-direction:column;height:100%;">
                    <div style="flex:1;margin:20px;">
                        <dv-scroll-board :config="config_device_record" />
                    </div>
                    <div style="margin:20px;flex:1">
                        <!-- <dv-charts ref="device_chart" :option="chart_option" /> -->
                        <div id="highChartForWXCW"></div>
                    </div>
                </div>
            </dv-border-box-8>
            <div class="main2">
                <dv-border-box-1>
                    <div style="display:flex;margin:50px;justify-content:space-between;">
                        <dv-decoration-1 style="width:200px;height:50px;" />
                        <dv-decoration-1 style="width:200px;height:50px;" />
                    </div>
                    <div style="font-size:30px;color:white;text-align:center;">当前展示<br>{{ currentDevice.remark }}</div>
                    <div style="display:flex;margin:50px;justify-content:center;">
                        <!-- <dv-active-ring-chart :config="config_device_count" style="width:100px;height:100px" /> -->
                    </div>
                </dv-border-box-1>
                <div class="main3">
                    <dv-border-box-9>
                        <div style="height:100%;display:flex;align-items:stretch">
                            <div
                                style="padding:20px;flex:1;display:flex;flex-direction:column;justify-content:center;align-items:center;">
                                <div style="color:white;font-size:30px;text-align:center;">温差报警</div>
                                <dv-digital-flop :config="wenChaBaoJing" />
                            </div>
                            <dv-decoration-2 :reverse="true" style="width:5px;" />
                            <div
                                style="padding:20px;flex:1;display:flex;flex-direction:column;justify-content:center;align-items:center;">
                                <div style="color:white;font-size:30px;text-align:center;">上下限报警</div>
                                <dv-digital-flop :config="shangXiaXianBaoJing" />
                            </div>
                        </div>
                    </dv-border-box-9>
                </div>
            </div>
        </div>
    </dv-full-screen-container>
</template>

<script>
import Vue from "vue";
import dataV from "@jiaminghi/data-view";
import Header from "./Header.vue";
import VueAwesomeSwiper from "vue-awesome-swiper";
import "swiper/css/swiper.css";
import HighCharts from "highcharts";
import Moment from "moment";

Vue.use(dataV);
Vue.use(VueAwesomeSwiper);
export default {
    components: { Header },
    data() {
        let that = this;
        return {
            //从服务器获取到的数据加载到HighCharts中
            dataListAll: [],
            //当前公司下所有设备的列表
            deviceList: [],
            config_device_record: {
                data: [],
            },
            wenChaBaoJing: { number: [0], content: "{nt}条" },
            shangXiaXianBaoJing: { number: [0], content: "{nt}条" },
            swiperHeight: 0,
            swiperOption: {
                slidesPerView: 3,
                direction: "vertical",
                autoplay: {
                    delay: 30000,
                    disableOnInteraction: false,
                },
                on: {
                    slideChange() {
                        that.getChartDataAndLoad(that.deviceList[this.activeIndex].serialNumber);
                        that.currentDevice = that.deviceList[this.activeIndex];
                        that.loadBaoJing();
                    },
                },

                //loop: true,
                // height: 500,
                //width: 300,
            },
            currentDevice: { remark: "" },
        };
    },
    methods: {
        getChartDataAndLoad(serialNumber) {
            let that = this;
            //获取过去三天的记录
            that.axios
                .post("WXCW01/GetList", {
                    serialNumber: serialNumber,
                    BeginDate: Moment().add(-3, "day"),
                    EndDate: Moment(),
                })
                .then(function (response) {
                    that.dataListAll = response.data.data;
                    that.loadChart();
                    that.load_config_device_record();
                });
            //获取报警
            //that.axios.post("");
        },
        load_config_device_record() {
            let that = this;
            let tmp = {
                header: ["时间", "t1", "t2", "t3", "t4", "t5", "t6", "t7", "t8"],
                data: [],
                columnWidth: [150],
                waitTime: 600000
            };
            for (var i = 0; i < that.dataListAll.length && i < 20; i++) {
                tmp.data.push([
                    that.dataListAll[i].dataTime,
                    that.dataListAll[i].t1 == 2500 ? "-" : (that.dataListAll[i].t1 / 10.0).toString(),
                    that.dataListAll[i].t2 == 2500 ? "-" : (that.dataListAll[i].t2 / 10.0).toString(),
                    that.dataListAll[i].t3 == 2500 ? "-" : (that.dataListAll[i].t3 / 10.0).toString(),
                    that.dataListAll[i].t4 == 2500 ? "-" : (that.dataListAll[i].t4 / 10.0).toString(),
                    that.dataListAll[i].t5 == 2500 ? "-" : (that.dataListAll[i].t5 / 10.0).toString(),
                    that.dataListAll[i].t6 == 2500 ? "-" : (that.dataListAll[i].t6 / 10.0).toString(),
                    that.dataListAll[i].t7 == 2500 ? "-" : (that.dataListAll[i].t7 / 10.0).toString(),
                    that.dataListAll[i].t8 == 2500 ? "-" : (that.dataListAll[i].t8 / 10.0).toString(),
                ]);
            }
            that.config_device_record = tmp;
        },
        loadChart() {
            let that = this;
            var mapData = [];
            mapData.push({ name: "T1", data: [] });
            mapData.push({ name: "T2", data: [] });
            mapData.push({ name: "T3", data: [] });
            mapData.push({ name: "T4", data: [] });
            mapData.push({ name: "T5", data: [] });
            mapData.push({ name: "T6", data: [] });
            mapData.push({ name: "T7", data: [] });
            mapData.push({ name: "T8", data: [] });
            for (var i = that.dataListAll.length - 1; i >= 0; i--) {
                if (that.dataListAll[i].t1 != 2500) mapData[0].data.push([Moment(that.dataListAll[i].dataTime).valueOf(), that.dataListAll[i].t1 / 10.0]);
                if (that.dataListAll[i].t2 != 2500) mapData[1].data.push([Moment(that.dataListAll[i].dataTime).valueOf(), that.dataListAll[i].t2 / 10.0]);
                if (that.dataListAll[i].t3 != 2500) mapData[2].data.push([Moment(that.dataListAll[i].dataTime).valueOf(), that.dataListAll[i].t3 / 10.0]);
                if (that.dataListAll[i].t4 != 2500) mapData[3].data.push([Moment(that.dataListAll[i].dataTime).valueOf(), that.dataListAll[i].t4 / 10.0]);
                if (that.dataListAll[i].t5 != 2500) mapData[4].data.push([Moment(that.dataListAll[i].dataTime).valueOf(), that.dataListAll[i].t5 / 10.0]);
                if (that.dataListAll[i].t6 != 2500) mapData[5].data.push([Moment(that.dataListAll[i].dataTime).valueOf(), that.dataListAll[i].t6 / 10.0]);
                if (that.dataListAll[i].t7 != 2500) mapData[6].data.push([Moment(that.dataListAll[i].dataTime).valueOf(), that.dataListAll[i].t7 / 10.0]);
                if (that.dataListAll[i].t8 != 2500) mapData[7].data.push([Moment(that.dataListAll[i].dataTime).valueOf(), that.dataListAll[i].t8 / 10.0]);
            }

            HighCharts.chart("highChartForWXCW", {
                tooltip: {
                    formatter: function () {
                        return this.series.name + "<br />温度：" + this.y + "℃<br />时间：" + Moment(this.x).format("YYYY-MM-DD HH:mm:ss");
                    },
                },
                chart: { backgroundColor: "rgba(0,0,0,0)" },
                title: { text: "温度曲线图" },
                credits: { enabled: false },
                exporting: { enabled: false },
                global: { useUTC: false },
                yAxis: { title: { text: "温度" } },
                xAxis: { type: "datetime" },
                series: mapData,
            });
        },
        loadBaoJing() {
            let that = this;
            that.axios
                .post("WXCW_BigScreen/GetBaoJing", {
                    onlyText: that.currentDevice.serialNumber,
                })
                .then(function (response) {
                    console.log(response.data.data);
                    that.shangXiaXianBaoJing = { number: [parseInt(response.data.data.split(",")[0])], content: "{nt}条" };
                    that.wenChaBaoJing = { number: [parseInt(response.data.data.split(",")[1])], content: "{nt}条" };
                });
        },
    },

    mounted() {
        let that = this;
        //获取同一个公司下的所有无线测温仪设备
        that.axios.post("WXCW_BigScreen/GetDeviceBySameCompanyName").then(function (response) {
            that.deviceList = response.data.data;
            if (that.deviceList.length > 0) {
                that.getChartDataAndLoad(that.deviceList[0].serialNumber);
                that.currentDevice = that.deviceList[0];
                that.loadBaoJing();
            }
        });

        // that.chart_option.series[0].data[6] = 8000;
        that.swiperHeight = that.$parent.$el.offsetHeight - 220;
    },
};
</script>

<style scoped>
.main {
    background-image: url("/images/bigscreen/bg1.png");
    display: flex;
    flex-direction: column;
}

.main1 {
    flex: 1;
    display: flex;
}

.main2 {
    width: 15%;
    display: flex;
    flex-direction: column;
}

.main3 {
    height: 400px;
    display: flex;
}
</style>