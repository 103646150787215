<template>
    <el-menu ref="elMenu" @select="handleSelect" :router="true" :default-active="defaultActive" background-color="#545c64" text-color="#fff" active-text-color="#ffd04b">
        <el-submenu v-for="menu in Sys_Menu.filter((p) => { return p.pid == 0; })" :key="menu.id" :index="menu.id + ''">
            <template slot="title"><i class="el-icon-location"></i><span>{{ lang == "EN" ? menu.englishName : (lang == "HK"
                ? menu.hK_Name : menu.name)
                    }}</span></template>
            <el-menu-item v-for="submenu in Sys_Menu.filter((p) => { return p.pid === menu.id; })" :key="submenu.id" :route="{ name: submenu.vueName, params: {}, }" :index="submenu.vueUrl">{{ lang == "EN" ?
                submenu.englishName : (lang == "HK" ? submenu.hK_Name : submenu.name) }}
            </el-menu-item>
        </el-submenu>
    </el-menu>
</template>
<script>
export default {
    name: "SumjSide",
    props: ["navHeader"],
    data() {
        let that = this;
        return {
            Sys_Menu: [],
            defaultActive: "",
            lang: that.$i18n.locale
        };
    },
    methods: {
        getMenu() {
            let that = this;
            that.axios
                .post("Sys_Menu/GetMyMenu", {
                    nav_Header: that.navHeader,
                })
                .then(function (response) {
                    that.Sys_Menu = response.data.data;
                })
                .catch(function (err) {
                    console.log(err);
                });
        },
        handleSelect(index) {
            let that = this;
            if (index == "/DeviceData/PZD101Dat") {
                open(process.env.VUE_APP_TurnUrl + "PZD100/DatForIframe");
            } else if (index == "/DeviceData/WXCW01Query") {
                that.axios.get("Base/GetCurrentUser").then(function (response) {
                    console.log(process.env.VUE_APP_TurnUrl + "Login/Index?OpenId=" + response.data.data.openId);
                    open(process.env.VUE_APP_TurnUrl + "Login/Index?OpenId=" + response.data.data.openId);
                });
            }
            else if (index == "/DeviceData/DPZS") {
                open("../BigScreenForWXCW2");
            }
        },
    },
    mounted: function () {
        let that = this;
        let tmp = that.$route.path.split("/");
        //特殊处理的导航
        if (that.$route.path.indexOf("WXCW81ProjectView") > -1) {
            that.defaultActive = that.$route.path.substring(0, that.$route.path.lastIndexOf("/")).replace("WXCW81ProjectView", "WXCW81MyProject");
        }
        else if (tmp.length > 3) {
            that.defaultActive = "/" + tmp[1] + "/" + tmp[2];
        } else {
            that.defaultActive = that.$route.path;
        }

        that.getMenu();
    },
};
</script>
