<template>
    <div>
        <div v-if="hasPower != null && hasPower" v-loading="loading">
            <van-cell-group title="注册机">
                <van-field label="产品名称" is-link readonly v-model="selectProduct.productName" @click="showPicker = true" input-align="right"></van-field>
                <van-field label="贴牌公司名称" is-link readonly v-model="companyName" @click="showCompanyNamePicker = true" input-align="right"></van-field>
                <van-field label="序列号" v-model="serialNumber" input-align="right" readonly @click="keyboardShow = !keyboardShow"></van-field>
            </van-cell-group>
            <el-row type="flex" justify="space-around" style="margin-top:10px">
                <el-col :span="7">
                    <van-button type="info" block @click="handleClickRegister(false)">注册</van-button>
                </el-col>
                <el-col :span="7">
                    <van-button type="primary" block @click="handleClickRegister(true)">注册并打印</van-button>
                </el-col>
                <el-col :span="7">
                    <van-button type="danger" block @click="handleRegisterByScan">扫码注册</van-button>
                </el-col>
            </el-row>
            <el-row type="flex" justify="center" style="margin-top: 100px;">
                <van-button type="primary" plain :url="shengChanGongYi_Url">生产工艺</van-button></el-row>
            <van-popup v-model="showPicker" position="bottom">
                <van-picker :columns="columns" show-toolbar @cancel="showPicker = false" @confirm="handleConfirm" value-key="productName"></van-picker>
            </van-popup>
            <van-popup v-model="showCompanyNamePicker" position="bottom">
                <van-picker :columns="companyNameList" show-toolbar @cancel="showCompanyNamePicker = false" @confirm="handleCompanyNameConfirm" value-key="dataText"></van-picker>
            </van-popup>

            <el-alert v-if="responseCode == 101" :title="responseData" center type="success" :closable="false" style="margin-top:10px">
            </el-alert>
            <el-alert v-else-if="responseCode == 201" :title="responseData" center type="error" :closable="false" style="margin-top:10px">
            </el-alert>
        </div>
        <el-alert v-else-if="hasPower != null && !hasPower" class="sumjcenter1" title="无权限" center type="warning" :closable="false">
        </el-alert>
        <custom-keyboard :keyboardShow.sync="keyboardShow" :value.sync="serialNumber" :confirm="v => keyboardShow = false"></custom-keyboard>
        <van-dialog v-model="pzd801_dialog" show-cancel-button @confirm="savePZD801DeviceConfig">
            <van-cell-group inset title="平整度配置">
                <van-cell title="允许使用7型"><van-switch v-model="pzd801_device_config.xing7" /></van-cell>
                <van-cell title="允许使用8型"><van-switch v-model="pzd801_device_config.xing8" /></van-cell>
            </van-cell-group>
        </van-dialog>
    </div>
</template>

<script>
import Vue from "vue";
import Vant from "vant";
import customKeyboard from "custom-keyboard-vue";
import "vant/lib/index.css";

Vue.use(Vant);
Vue.use(customKeyboard);

export default {
    data() {
        return {
            keyboardShow: false,
            selectProduct: {},
            showPicker: false,
            columns: [],
            loading: false,
            serialNumber: "",
            responseCode: "",
            responseData: "",
            hasPower: null,
            companyName: "",
            showCompanyNamePicker: false,
            companyNameList: [],
            pzd801_dialog: false,
            pzd801_device_config: {},

        };
    },
    computed: {
        shengChanGongYi_Url() {
            let that = this;
            let baseUrl = "https://bjlcyq.cn/download/";
            return baseUrl + that.selectProduct.productType + "/" + that.selectProduct.productType + ".pdf";
        }
    },
    methods: {
        handleConfirm(arr0, arr1) {
            let that = this;
            that.selectProduct = that.columns[arr1[0]].children[arr1[1]];
            that.showPicker = false;
        },
        handleCompanyNameConfirm(obj) {
            let that = this;
            that.companyName = obj.dataText;
            that.showCompanyNamePicker = false;
        },
        handleClickRegister(ifPrint) {
            let that = this;
            that.loading = true;
            that.responseCode = "";
            that.axios
                .post("Base_Device/Register", {
                    productName: that.selectProduct.productName,
                    productId: that.selectProduct.id,
                    serialNumber: that.serialNumber,
                    deviceType: that.selectProduct.productType,
                    companyName: that.companyName,
                    ifPrint: ifPrint,
                })
                .then(function (response) {
                    that.loading = false;
                    that.responseCode = response.data.code;
                    that.responseData = response.data.data;
                    that.afterRegisterDevice(response.data.code, that.serialNumber, that.selectProduct.productType);
                });
        },
        handleRegisterByScan() {
            let that = this;
            that.wx.scanQRCode({
                needResult: 1, // 默认为0，扫描结果由微信处理，1则直接返回扫描结果，
                scanType: ["qrCode"],
                success: function (res) {
                    let url = new URL(res.resultStr);
                    let serialNumber = url.searchParams.get("s");
                    let deviceType = url.searchParams.get("n");
                    that.loading = true;
                    that.responseCode = "";
                    that.axios
                        .post("Base_Device/Register", {
                            serialNumber: serialNumber,
                            deviceType: deviceType,
                            ifPrint: false,
                        })
                        .then(function (response) {
                            that.loading = false;
                            that.responseCode = response.data.code;
                            that.responseData = response.data.data;
                            that.afterRegisterDevice(response.data.code, serialNumber, deviceType);
                        });
                },
            });
        },
        afterRegisterDevice(code, serialNumber, deviceType) {
            let that = this;
            if (code == 101) {
                if (deviceType == "PZD801") {
                    that.axios.post("PZD801/GetDeviceConfig", { onlyText: serialNumber }).then((response) => {
                        that.pzd801_device_config = response.data.data;
                        that.pzd801_dialog = true;
                    });
                }
                //如果还有其它设备需要配置，在这里用else if
            }
        },
        savePZD801DeviceConfig() {
            let that = this;
            that.axios.post("PZD801/UpdateDeviceConfig", that.pzd801_device_config).then((response) => {
                if (response.data.code == 101) {
                    that.$notify({ type: "success", message: "保存成功" });
                    that.pzd801_dialog = false;
                }
            });
        },
    },
    mounted() {
        let that = this;
        document.title = "注册机";

        //获取权限
        that.axios.post("Base/HasPower", { powerKey: "SerialNumberRegister" }).then(function (response) {
            that.hasPower = response.data.data;
        });
        // that.axios.get("Product/GetHasNumber").then(function (response) {
        //   that.columns = response.data.data;
        // });
        that.axios.post("Product/GetProductForPick").then(function (response) {
            that.columns = response.data.data;
        });
        that.axios
            .post("Base_SystemBaseData/GetListByDataType", {
                dataType: "贴牌公司名称",
            })
            .then(function (response) {
                that.companyNameList = response.data.data;
            });
        //获取公众号扫码的凭证
        that.axios.post("OfficialAccount/GetWXScanAPI", { onlyText: window.location.href }).then(function (response) {
            that.wx = require("weixin-js-sdk");
            that.wx.config({
                debug: false,
                appId: response.data.data.appId,
                timestamp: response.data.data.timestamp,
                nonceStr: response.data.data.nonceStr,
                signature: response.data.data.signature,
                jsApiList: ["scanQRCode"],
            });
            that.wx.ready(function () { });
            that.wx.error(function (err) {
                alert("error" + JSON.stringify(err));
            });
        });
    },
};
</script>

<style scoped>
.sumjcenter1 {
    margin-top: 50%;
}
</style>
